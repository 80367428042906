import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { api } from '../utils/axios'
import { IUser } from '../types/user.types'
import { useForm } from 'antd/es/form/Form'
import { FlexSpace } from './FlexSpace'
import {Project} from "../types/todo.types";
import {OptionType} from "./ToDoFilter";

interface ToDoFilterProps {
  onStatusChange: (statusId: number[]) => void
  onMemberChange: (memberIds: number[]) => void
  project: Project | null
}

export const ProjectsFilter = ({ project, onMemberChange, onStatusChange }: ToDoFilterProps) => {
  const [form] = useForm()
  const [members, setMembers] = useState<OptionType[]>([]);
  const statuses: OptionType[] = [
    {label: 'New', value: 1},
    {label: 'In progress', value: 2},
    {label: 'Completed', value: 3},
    {label: 'On hold', value: 4},
    {label: 'Canceled', value: 5},
  ];
  const membersList: any = members
      .map((v) => ({
        name: v.label,
        value: v.value,
        label: v.label,
      }))

  const statusesList: any = statuses
      .map((v) => ({
        name: v.label,
        value: v.value,

        label: (
            <FlexSpace>
              <span>{v.label}</span>
            </FlexSpace>
        )
      }))

  useEffect(() => {
    if (project?.application) {
      fetchMembers(project?.application?.id)
    }
  }, [project?.application])

  const fetchMembers = async (companyId: number) => {
    try {
      const {data} = await api.get(`/applications/${companyId}/members`)
      const newMembers = data.map((member: IUser) => ({
        ...member,
        value: member.id,
        label: member.firstName + ' ' + member.lastName
      }))
      setMembers(newMembers);
    } catch (error) {
      console.error('Error fetching members:', error)
      // Handle error appropriately
    }
  }

  return (
    <Form form={form} layout="vertical">
      <FlexSpace>
        <Form.Item label="Filter by Assignee" name="user">
          <Select
            className="SelectPrimary"
            maxTagCount={1}
            placeholder="Select members"
            style={selectStyle}
            onChange={(values) => {
              onMemberChange(values)
            }}
            filterOption={(input, option) =>
                option?.name?.toLowerCase().includes(input.toLowerCase())
            }
            allowClear
            showSearch
            options={membersList}
            mode="multiple"
          >
          </Select>
        </Form.Item>
        <Form.Item label="Filter by Status" name="status">
          <Select
              className="SelectPrimary"
              maxTagCount={1}
              placeholder="Select status"
              style={selectStyle}
              onChange={(values) => {
                onStatusChange(values);
              }}
              filterOption={(input, option) =>
                  option?.name?.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              showSearch
              options={statusesList}
              mode="multiple"
          />
        </Form.Item>
      </FlexSpace>
    </Form>
  )
}

const selectStyle: React.CSSProperties = {
  width: 300,
  padding: 0
}
