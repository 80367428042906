import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Navigate } from 'react-router-dom'
import { Button, Divider, Table, message } from 'antd'

import { Page } from 'components/Page'
import { FlexSpace } from 'components/FlexSpace'
import { HeadTitle } from 'components/HeadTitle'

import { currentUser } from 'recoil/user.state'
import { api } from 'utils/axios'
import { errorMsg } from 'utils/errorMsg'
import { toSafeDateString } from 'utils/dates'
import { toCurrency } from 'utils/numbers'

export const Admin: ReactFC = () => {
  const user = useRecoilValue(currentUser)

  const [link, setLink] = useState('')
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState<any[]>([])

  useEffect(() => {
    if (!user?.admin) return
    setLoading(true)
    api.get('/admin/users')
      .then(res => setList(res.data))
      .catch(err => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
  }, [!user?.admin])

  if (!user?.admin) return <Navigate to="/" />

  const fileName = (new Date()).toLocaleDateString() + ' Users Data.xlsx'

  const getUsers = async () => {
    setLoading(true)
    api.get('/admin/users/xlsx?' )
      .then(r => {
        setLink(r.data.file as string)
        
        setTimeout(() => {
          const a = document.getElementById('adminlink1')
          if (a) a.click()
        }, 50)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Page title={'Admin'}>
      <HeadTitle title="Admin" />
      <FlexSpace direction="vertical" style={{ minHeight: 500 }}>
        <Button loading={loading} onClick={getUsers}>
          Download Users Data
        </Button>
        <Divider />
        <Table
          rowKey={'id'}
          size="small"
          dataSource={list} 
          loading={loading} 
          columns={columns} 
          pagination={false}
          scroll={{ y: 600 }}
        />
      </FlexSpace>
      <div style={{ visibility: 'hidden' }}>
        <a id="adminlink1" href={link} target="_blank" rel="noreferrer" download={fileName}>
          Download Users Data
        </a>
      </div>
    </Page>
  )
}

const columns = [
  {
    id: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 50,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
    width: 120,
    ellipsis: true
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
    width: 120,
    ellipsis: true
  },
  {
    title: 'Created On',
    dataIndex: 'created_on',
    key: 'created_on',
    width: 110,
    render: (v: string) => toSafeDateString(v)
  },
  {
    title: 'Last Access',
    dataIndex: 'last_access',
    key: 'last_access',
    width: 110,
    render: (v: string) => v ? toSafeDateString(v) : ''
  },
  {
    title: 'Revenue',
    dataIndex: 'value',
    key: 'value',
    width: 100,
    render: (v: number) => v ? toCurrency(v) : ''
  },
  {
    title: 'Group',
    dataIndex: 'group',
    key: 'group',
    width: 90
  },
  {
    title: 'Owner',
    dataIndex: 'ownership',
    key: 'ownership',
    width: 90
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    width: 100,
    ellipsis: true
  }
]

export const EXCEL_RESPONSE_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
