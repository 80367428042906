import React, {useState, useEffect} from 'react';
import {Table, Typography} from 'antd';
import {toCurrency} from '../utils/numbers';
import {formatDateByPeriod, toSafeDateString} from '../utils/dates';
import {AccountTypes} from '../types/chart.types';
import {DataItem, NestedTableProps} from "../types/financial.type";
import {queryQboTransactions} from "../pages/analytics/utils";
import {useRecoilValue} from "recoil";
import {currentCompany} from "../recoil/companies.state";
import {DetailsModal} from "../pages/compliance/components/TxDetails.modal";

export const NestedTable: React.FC<NestedTableProps> = ({
                                                            data,
                                                            accountType,
                                                            expandLevel,
                                                            dateRange,
                                                            period,
                                                            accountingMethod,
                                                            startDate,
                                                            serializedColumnBy
                                                        }) => {
    const company = useRecoilValue(currentCompany);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [loading, setLoading] = useState(false)
    const [details, setDetails] = useState(false)
    const [detailsData, setDetailsData] = useState<any>()

    const columns = [
        {
            title: <Typography.Text
                style={{paddingRight: 10, fontWeight: 'bolder'}}>{AccountTypes[accountType]}</Typography.Text>,
            key: 'name',
            render: (v: any, record: any) =>
                <Typography.Text
                    style={isRowExpanded(record.key) || (record.type === "Summary" || (record.type === 'Section' && !record.children?.length)) ? {fontWeight: 'bolder'} : {}}>
                    {v.name}
                </Typography.Text>
        },
        {
            title: <Typography.Text style={{paddingRight: 10, fontWeight: 'bolder'}}>
                {formatDateByPeriod(dateRange?.base?.start_date, period)}
            </Typography.Text>,
            key: 'amount',
            dataIndex: 'baseValue',
            render: (v: any, record: any) => renderContent(toCurrency(v), record, (record.type === "Summary" || (record.type === 'Section' && !record.children?.length)))
        },
        {
            title: <Typography.Text style={{ paddingRight: 10, fontWeight: 'bolder' }}>
                {formatDateByPeriod(dateRange?.comparative?.start_date, period)}
            </Typography.Text>,
            key: 'comparativeValue',
            dataIndex: 'comparativeValue',
            render: (v: any, record: any) => renderContent(toCurrency(v), record, (record.type === "Summary" || (record.type === 'Section' && !record.children?.length)))
        },
    ];
    if (accountType !== 'CF') {
        columns.push({
                title: <Typography.Text style={{paddingRight: 10, fontWeight: 'bolder'}}>Variance $</Typography.Text>,
                key: 'varianceAmount',
                dataIndex: 'varianceAmount',
                render: (v: any, record: any) => {
                    const varianceStyle = v > 0 ? {color: 'green'} : v < 0 ? {color: 'red'} : {};
                    const varianceText = v ? `${toCurrency(v)}` : '-';
                    return renderContent(varianceText, record, (record.type === "Summary" || (record.type === 'Section' && !record.children?.length)), varianceStyle);
                }

            },
            {
                title: <Typography.Text style={{paddingRight: 10, fontWeight: 'bolder'}}>Variance %</Typography.Text>,
                key: 'variancePercentage',
                dataIndex: 'variancePercentage',
                render: (v: any, record: any) => {
                    const varianceStyle = v > 0 ? {color: 'green'} : v < 0 ? {color: 'red'} : {};
                    const varianceText = v ? `${v.toFixed(2)} %` : '-';
                    return renderContent(varianceText, record, (record.type === "Summary" || (record.type === 'Section' && !record.children?.length)), varianceStyle);
                }

            })
    }

    const preprocessData = (data: DataItem[], parentId: string | null = null): DataItem[] => {
        return data;
    };

    const updateExpandedRowKeys = (data: DataItem[], level: number, currentKeys: string[] = []): string[] => {
        data.forEach(item => {
            if (level > 1 && item.children && item.children.length > 0) {
                currentKeys.push(item.key);
                updateExpandedRowKeys(item.children, level - 1, currentKeys);
            }
        });
        return currentKeys;
    };

    const rowClassName = (record: DataItem) => {
        let className = '';

        if (isRowExpanded(record.key) || (record.type === "Summary" || (record.type === 'Section' && !record.children?.length))) {
            className += 'borderBottomRow ';
        }

        if (record.id) {
            className += 'clickable';
        }

        return className.trim();
    };

    const isRowExpanded = (key: string) => expandedRowKeys.includes(key);

    const renderContent = (text: string | number, record: DataItem, isBold = false, additionalStyle: React.CSSProperties = {}) => {
        if (isRowExpanded(record.key ?? '')) return <></>;

        return (
            <Typography.Text style={{paddingRight: 10, fontWeight: isBold ? 'bolder' : 'normal', ...additionalStyle}}>
                {text}
            </Typography.Text>
        );
    };
    const calculateDates = (period: string, currentDate: any) => {
        let start_date, end_date
        if (period === 'month') {
            start_date = currentDate.clone().startOf('month')
            end_date = currentDate.clone().endOf('month')
        } else if (period === 'quarter') {
            start_date = currentDate.clone().startOf('quarter')
            end_date = currentDate.clone().endOf('quarter')
        } else if (period === 'year') {
            start_date = currentDate.clone().startOf('year')
            end_date = currentDate.clone().endOf('year')
        }
        return { start_date, end_date }
    }

    const showDetails = (accountQboId: string, name: string) => {
        setLoading(true)
        const { start_date, end_date } = calculateDates(serializedColumnBy, startDate)

        queryQboTransactions({
            applicationId: company?.id,
            from: toSafeDateString(start_date),
            to: toSafeDateString(end_date),
            accountQboId: accountQboId,
            unreconciled: false,
            excludeSub: true,
            accountingMethod: accountingMethod
        })
            .then((r) => {
                const txs = {
                    type: name,
                    list: r.mapList.map((v: any) => ({...v, acc: { name, id: accountQboId } })),
                }
                setDetailsData({ txs: [txs] })
                setTimeout(() => setDetails(true))
                console.log(123, r.mapList)
            })
            .finally(() => setLoading(false))
    }


    useEffect(() => {
        setExpandedRowKeys(updateExpandedRowKeys(preprocessData(data), expandLevel));
    }, [expandLevel, data]);

    return (
        <>
            <Table
                key={loading ? 'b-s-loading' : 'b-s-loaded'}
                loading={loading}
                columns={columns}
                className="financialsTable"
                dataSource={preprocessData(data)}
                expandedRowKeys={expandedRowKeys}
                onExpand={(expanded, record) => {
                    const newKeys = expanded
                        ? [...expandedRowKeys, record.key]
                        : expandedRowKeys.filter(key => key !== record.key);
                    setExpandedRowKeys(newKeys);
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            if (!record.id) return
                            showDetails(record.id, record.name)
                        }
                    }
                }}
                pagination={false}
                expandIcon={() => null}
                rowClassName={rowClassName}
            />
            <DetailsModal
                data={detailsData}
                visible={details}
                onCancel={() => setDetails(false)}
            />
        </>
    );
};

