import { Navigate, Route, Routes } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { ProtectedRoute } from 'components/ProtectedRoute'
import { SessionTimeout } from 'components/SessionTimeout'

import { SignIn } from 'pages/auth/SignIn.page'
import { ResetPass } from 'pages/auth/ResetPass.page'
import { SignUp } from 'pages/auth/SignUp'
import { NewPass } from 'pages/auth/NewPass'
import { AppSumo } from 'pages/auth/AppSumo'

import { Billing } from 'pages/billing/Billing.page'
import { Account } from 'pages/account/Account.page'

import { Dashboard } from 'pages/dashboard/Dashboard.page'

import { CompanySettings } from 'pages/company/Settings.page'
import { Compliance } from 'pages/compliance/Compliance.page'
import { Reconcile } from 'pages/reconcile/Reconcile.page'
import { Accountant } from 'pages/accountant/Accountant.page'
import { Analytics } from 'pages/analytics/Analytics.page'
import { Admin } from 'pages/admin/Admin.page'
import { Landing } from 'pages/auth/Landing'
import { VerifyEmail } from 'pages/auth/VerifyEmail'
import { CustomRules } from 'pages/custom/CustomReview.page'

import { QboReconcile } from 'pages/utils/QboReconcile'
import { QboSync } from 'pages/utils/QboSync'

import { SearchParams } from 'components/SearchParams'

import { RutterPage } from 'pages/rutter/Rutter.page'
import { FinchPage } from 'pages/finch/Finch.page'
import { ToDoPage } from 'pages/todo/Todo.page'
import { SetupPage } from 'pages/setup/Setup'
import { ProjectsPage } from 'pages/projects/Projects.page'
import { TasksPage } from 'pages/tasks/Tasks.page'
import { TemplatesPage } from 'pages/templates/Templates.page'
import { ShopifyRedirect } from 'pages/utils/ShopifyRedirect'

import { config } from 'utils/config'


export const AppRoutes = () => {
  return (
    <Router>
      <SearchParams />
      <SessionTimeout />
      <Routes>
        <Route path="/landing" element={<Landing />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<GoogleReCaptchaProvider {...config.recaptcha}><SignUp /></GoogleReCaptchaProvider>} />
        <Route path="/appsumo" element={<AppSumo />} />
        <Route path="/reset" element={<NewPass />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/reset-password" element={<ResetPass />} />
        <Route path="/quickbooks" element={<QboSync />} />
        <Route path="/redirect/shopify" element={<ShopifyRedirect />} />
        <Route path="/reconcile" element={<QboReconcile />} />
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/tasks" element={<TasksPage />} />
          <Route path="/projects/:id" element={<ToDoPage />} />
          <Route path="/templates" element={<TemplatesPage />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/account" element={<Account />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/welcome" element={<SetupPage type='welcome' />} />
          <Route path="/addcompany" element={<SetupPage type="new" />} />
          <Route path="/company/:id">
            <Route path="setup" element={<SetupPage type="edit" />} />
            <Route path="settings/*" element={<CompanySettings />} />
            <Route path="compliance/*" element={<Compliance />} />
            <Route path="custom/*" element={<CustomRules />} />
            <Route path="rutter/*" element={<RutterPage />} />
            <Route path="finch/*" element={<FinchPage />} />
            <Route path="reconcile/*" element={<Reconcile />} />
            <Route path="analytics/*" element={<Analytics />} />
            <Route path="advisory/*" element={<Accountant />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  )
}
