import { useEffect, useState } from 'react'
import { Card, Typography } from 'antd'

import { Btn } from 'components/Btn'
import { FlexSpace } from 'components/FlexSpace'
import { CSVBtn } from 'components/CSVBtn'

import { csvBoxToTxs } from 'utils/tx'
import { ITx } from 'types/tx.types'
import { IAccount } from 'types/company.types'

const { Title, Text } = Typography

interface Props {
  account?: IAccount
  onChange: (tsx: ITx[]) => void
  onCancel: () => void
}

export const Upload: ReactFC<Props> = (props) => {
  const { account, onChange, onCancel } = props

  const [fileName, setFileName] = useState('')
  const [isCC, setIsCC] = useState(!!account?.isCredit)

  console.log('Upload isCC', isCC)

  useEffect(() => {
    setFileName('')
    setIsCC(!!account?.isCredit)
    // console.log('setIsCC', !!account?.isCredit)
    // onChange([])
  }, [account])

  const onImport = (_: boolean, data: any) => {
    if (data?.rows) {
      // console.log('data', data.rows[0], isCC, account?.isCredit, account)
      const txs = csvBoxToTxs(data.rows, false)
      console.log('data txs', txs[0], isCC,account?.isCredit)
      setFileName(data.original_filename)
      onChange(txs)
    }
  }

  const handleReset = () => {
    setFileName('')
    onCancel()
  }

  return (
    <FlexSpace direction="vertical" style={{ marginBottom: 30 }}>
      <Title level={5}>Upload bank statement</Title>
      <FlexSpace direction="vertical" size="large">
        <Text>
          Please upload your bank statement in CSV format. <br />Please note:
          <ul>
            <li>Checking Accounts = Assets</li>
            <li>Credit Cards = Liabilities</li>
          </ul>
        </Text>
        {!fileName && (
          <CSVBtn onImport={(_, data) => onImport(_, data)} isCC={account?.isCredit} key={account?.qboId} />
        )}
        {!!fileName && (
          <Card style={{ marginBottom: 10 }}>
            <FlexSpace spacebetween>
              <Text strong>{fileName}</Text>
              <Btn title="Remove" type="link" onClick={handleReset} />
            </FlexSpace>
          </Card>
        )}
        {/* {error && <Alert type="error" message="We have run into an error" description={error} />} */}
      </FlexSpace>
    </FlexSpace>
  )
}
