import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Form, Input, Spin, Typography, message } from 'antd'

import { Btn } from 'components/Btn'

import { rules } from 'pages/auth/auth.utils'
import { api } from 'utils/axios'
import { errorMsg } from 'utils/errorMsg'
import { authAtom } from 'recoil/auth.state'

export const ChangeEmail = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const setAuth = useSetRecoilState(authAtom)
  const handleLogout = () => setAuth(null)

  const handleSubmit = (data: any) => {
    const { newemail: email, newemail2 } = data

    if (!email) 
      return message.error('Please enter a new email address', 5)
    
    if (email !== newemail2) 
      return message.error('Emails do not match', 5)

    setLoading(true)

    api
      .post('/auth/update-email', { email })
      .then(() => {
        message.success('Email successfully changed! You will be logged out. Please use your new email to relogin', 5)
        form.resetFields()
        handleLogout()
      })
      .catch((err) => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
  }

  const formProps = {
    form,
    layout: 'vertical' as any,
    style: { width: 300 },
    requiredMark: false,
  }

  return (
    <Spin spinning={loading}>
      <Typography.Title level={4}>Change Email</Typography.Title>
      <Form {...formProps} onFinish={handleSubmit}>
        <Form.Item name="newemail" label="New Email" rules={rules.email}>
          <Input placeholder="my@new.email" autoComplete="new-email" />
        </Form.Item>
        <Form.Item name="newemail2" label="Repeat New Email" rules={rules.email}>
          <Input placeholder="my@new.email" autoComplete="new-email2" />
        </Form.Item>
        <Form.Item>
          <Btn title="Submit" type="primary" htmlType="submit" block loading={loading}  />
        </Form.Item>
      </Form>
    </Spin>
  )
}
