import { IReport, IRow } from "types/qbo.types"
import { api } from "utils/axios"
import { toCurrency } from "utils/numbers"

export function parseData(data: IReport): DataType[] {
  return toTree(data.Rows.Row)
}

export function toTree(rows: IRow[], key = '0'): DataType[] {
  return rows.map((row, i) => {
    // console.log(i, row)
    const headerName = row.Header?.ColData[0].value
    const headerValue = row.Header?.ColData[1].value || '0'

    const summaryName = row.Summary?.ColData[0].value
    const summaryValue = row.Summary?.ColData[1]?.value || '0'

    // const v = parseFloat(summaryValue)

    if (row.type === 'Data') {
      const name = row.ColData?.[0].value || ''
      const value = row.ColData?.[1].value || ''
      return {
        id: row.ColData?.[0].id || '',
        key: key + '-' + i,
        name: name,
        value: toCurrency(parseFloat(value || '0')),
        children: undefined,
        bold:
          name.toUpperCase() === 'NET INCOME' ||
          name.toUpperCase() === 'NET OTHER INCOME' ||
          name.toUpperCase() === 'NET OPERATING INCOME'
      }
    }

    const summaryRow = summaryName
      ? {
          key: key + '-' + i + '-summary',
          name: summaryName || '',
          value: toCurrency(parseFloat(summaryValue)),
          bold: true,
          total: true
        }
      : undefined

    const children = row.type === 'Section' && row.Rows?.Row 
      ? toTree(row.Rows?.Row || [], key + '-' + i) 
      : undefined

      if (summaryRow && children)
        children?.push(summaryRow)
    
      if (summaryRow && !children)
        return summaryRow

    return {
      header: true,
      key: key + '-' + i,
      name: headerName || '',
      value: toCurrency(parseFloat(summaryValue)),
      value2: parseFloat(headerValue) ? toCurrency(parseFloat(headerValue)) : '',
      children
    }
  })
}

export interface DataType {
  id?: string
  key: string
  name: string
  value: string
  value2?: string
  children?: DataType[]
  bold?: boolean
  total?: boolean
  header?: boolean
}

export function queryQboTransactions(params: any) {
  return api.get('/integrations/qbo/transactions', { params })
    .then(({ data }) => data)
}