import { Suspense, useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useSetRecoilState } from 'recoil'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Tabs, message } from 'antd'

import { Page } from 'components/Page'
// import { HelpBtn } from 'components/HelpBtn'
import { FullLoader } from 'components/FullLoader'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { HeadTitle } from 'components/HeadTitle'

import { ReconcileRun } from './tabs/ReconcileRun'
import { ReconcileHistory } from './tabs/ReconcileHistory'
import { ReconcileResult } from './tabs/ReconcileResult'

import { currentCompany, currentReconciliations } from 'recoil/companies.state'
import { companiesAtom, companyAtom } from 'recoil/companies.state'
import { IReconcile } from 'types/reconcile.types'
import { api } from 'utils/axios'

export const Reconcile: ReactFC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const company = useRecoilValue(currentCompany)
  const companies = useRecoilValue(companiesAtom)
  const list = useRecoilValue(currentReconciliations)
  const setCompany = useSetRecoilState(companyAtom)
  const refreshRecs = useRecoilRefresher_UNSTABLE(currentReconciliations)

  const viewOnly = company?.role.id === 2000

  const [result, setResult] = useState<IReconcile>()
  const [items, setItems] = useState<any>(getItems(viewOnly, !!result))
  
  const baseMatchUrl = `/company/:id/reconcile`
  const baseUrl = `/company/${company?.id}/reconcile`
  const match = useMatch(baseMatchUrl + '/results/:resultId')
  const pathname = location.pathname.replace(baseUrl, '')
  const resultId = (match as any)?.params.resultId
  const activeKey = match ? '/results/' : pathname

  const baseMatch = useMatch(baseMatchUrl + '/run')

  useEffect(() => {
    if (!baseMatch?.params?.id) return
    const id = parseInt(baseMatch.params.id)
    const nextcompany = companies.find(v => v.id === id)
    // console.log('nextcompany', nextcompany, companies)
    if (!nextcompany && companies.length) {
      message.error('Cannot reconcile the current company. Please contact Equility support.')
      return navigate('/')
    }

    if (id !== company?.id) {
      setCompany(id)
    }
  }, [baseMatch])

  useEffect(() => {
    const r = list.find(v => v.reconciliation_id === parseInt(resultId))
    if (resultId !== 'current') {
      setResult(r)
      setItems(getItems(viewOnly, !!r))
    } else 
      setItems(getItems(viewOnly, true))
  }, [list, resultId, company])

  const handleChange = (key: string) => navigate(`${baseUrl}${key}`)

  const handleRun = (rec: IReconcile) => {
    saveResult(rec)
      .then(r => {
        setResult(r.data)
        setItems(getItems(viewOnly, !!rec))
        refreshRecs()
        navigate(baseUrl + '/results/' + r.data.reconciliation_id)
      })
  }

  const saveResult = (rec: IReconcile) => {
    const payload = { 
      ...rec, 
      application_id: company?.id,
      result: JSON.stringify([]),
      temp_data: JSON.stringify(rec.temp_data),
    }
    
    return api.post(`/reconciliation`, payload)
  }

  // log('Reconcile', result, resultId, activeKey, items)

  if (!company) return <FullLoader />
  if (!result?.result && activeKey === '/results/' && resultId === 'current') 
    return <Navigate replace to={`${baseUrl}/run`} />

  return (
    <Page title={'Reconciliation'}>
      <HeadTitle title="Reconciliation" />
      <Tabs
        items={items}
        onChange={(key) => handleChange(key)}
        activeKey={activeKey}
        className="NoSelect"
        style={{ marginBottom: -16 }}
        // tabBarExtraContent={<HelpBtn type="text" />}
      />
      <ErrorBoundary>
        <Suspense fallback={<FullLoader />}>
          <Routes>
            <Route path="/run" element={<ReconcileRun onSubmit={handleRun} />} />
            <Route path="/results" element={<ReconcileHistory list={list} company={company} />} />
            <Route path="/results/:id" element={<ReconcileResult result={result}  />} />
            <Route path="*" element={<Navigate replace to={viewOnly ? `${baseUrl}/results` : `${baseUrl}/run`} />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </Page>
  )
}

function getItems(viewOnly: boolean, result: boolean) {
  const r =  result
    ? [
        { label: 'Run', key: '/run' },
        { label: 'History', key: '/results' },
        { label: 'Results', key: '/results/' }
      ]
    : [
        { label: 'Run', key: '/run' },
        { label: 'History', key: '/results' }
      ]

  if (viewOnly) r.shift()
  return r
}
