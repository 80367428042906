import React, {useEffect, useRef, useState} from 'react';
import {List, Avatar, Input, Card, Typography, Upload, message, Button} from 'antd';
import {SendOutlined, UploadOutlined} from '@ant-design/icons';
import {Btn} from "./Btn";
import {FlexSpace} from "./FlexSpace";
import {api} from "../utils/axios";
import {useRecoilValue} from "recoil";
import {currentUser} from "../recoil/user.state";
import {currentCompany} from "../recoil/companies.state";
import {CommentsResponse} from "../types/comments.types";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import {errorMsg} from "../utils/errorMsg";
import {UploadChangeParam, UploadFile} from "antd/es/upload/interface";
import {getFileNameFromUrlWithQuery} from "../utils/images";

interface Props {
    entityId: number;
    entityType: string
}

export const CommentSection: React.FC<Props> = ({entityId, entityType}) => {
    const [comments, setComments] = useState<CommentsResponse>({count: 0, data: []});
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState('');
    const user = useRecoilValue(currentUser)
    const company = useRecoilValue(currentCompany)
    const [page, setPage] = useState(1);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const doneLinkRef = useRef<HTMLAnchorElement>(null)

    const getComments = async () => {
        const params = {
            entityId,
            entityType,
            offset: 0,
            limit: 10
        };
        const {data} = await api.get('/comments', {params});

        setComments({
            count: data.count,
            data: data.data
        });
    };


    const moreComments = async () => {
        const params = {
            entityId,
            entityType,
            offset: page * 10,
            limit: 10
        };
        const {data} = await api.get('/comments', {params});

        const newData = [...comments.data, ...data.data];
        setComments({count: data.count, data: newData});
        setPage(page + 1);
    };

    useEffect(() => {
        setValue('');
        setPage(1)
        getComments();
    }, [entityId])

    const handleSubmit = async () => {
        if (!value && !fileList.length) return;
        setSubmitting(true);
        const newFiles = fileList.filter((file: UploadFile) => file.originFileObj);
        const promises = newFiles.map(file => {
            const formData = new FormData();
            file.originFileObj && formData.append('file', file.originFileObj);
            return api.post(`comments/file-upload`, formData)
                .then(res => res.data.url)
                .catch(err => {
                    message.error(errorMsg(err), 5);
                    return null;
                });
        });
        const results = await Promise.all(promises);
        const allFileUrls = results.filter(url => url !== null);

        const body = {
            params: {
                applicationId: company?.id
            },
            body: {
                entityType,
                entityId,
                user: user?.id,
                body: value,
                files: allFileUrls
            }
        }
        await api.post<CommentsResponse>(`comments`, body);
        setValue('');
        setFileList([]);
        await getComments();
        setSubmitting(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };

    const handleUploaderChange = async (info: UploadChangeParam<UploadFile>) => {
        const {fileList: newFileList} = info;
        setFileList(newFileList);
    };

    return (
        <>
            <Typography.Text>{comments?.count} {comments?.count > 1 ? 'comments' : 'comment'}</Typography.Text>
            {!!comments.data.length && <div>
              <div
                id="scrollableDiv"
                style={{
                    height: 300,
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
              >
                <InfiniteScroll
                  dataLength={comments.data.length}
                  next={moreComments}
                  hasMore={comments.data.length < comments.count}
                  style={{
                      display: 'flex',
                      flexDirection: 'column-reverse'
                  }}
                  inverse={true}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  <List
                    className='infiniteScrollList'
                    dataSource={comments?.data}
                    style={{
                        display: 'flex',
                        flexDirection: 'column-reverse'
                    }}
                    renderItem={(item) => item && item.user && (
                        <li style={{marginBottom: '10px'}}>
                            <Card>
                                <Card.Meta
                                    avatar={<Avatar src={item.user.photoUrl}/>}
                                    title={`${item.user.firstName} ${item.user.lastName}`}
                                    description={
                                        <div>
                                            {item.body}
                                            {!!item.files?.length && item.files.map((file) => (
                                                <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                                                    <a href={file} rel="noreferrer"
                                                       target='_blank'>{getFileNameFromUrlWithQuery(file)}</a>
                                                </div>))}
                                        </div>
                                    }
                                />
                                <div style={{
                                    marginTop: 10,
                                    textAlign: 'right',
                                    fontSize: 12
                                }}>{dayjs(item.createdAt).fromNow()}</div>
                            </Card>
                        </li>
                    )}
                  />
                  <Card>
                  </Card>
                </InfiniteScroll>

              </div>
            </div>}

            <Input.TextArea placeholder='Add a comment...' autoSize={{minRows: 3}} onChange={handleChange}
                            value={value}/>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 10}}>
                <Upload
                    beforeUpload={() => false}
                    listType="text"
                    fileList={fileList}
                    onChange={handleUploaderChange}>
                    <Button style={{display: 'none'}} ref={doneLinkRef}
                            icon={<UploadOutlined/>}/>
                </Upload>

                <FlexSpace style={{marginTop: 10}}>
                    <Btn icon={<UploadOutlined/>} onClick={() => doneLinkRef.current?.click()}/>
                    <Btn
                        type="primary"
                        icon={<SendOutlined/>}
                        loading={submitting}
                        onClick={handleSubmit}
                        style={{marginLeft: 10}}
                    />
                </FlexSpace>
            </div>
        </>
    );
};

