import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'
import { Divider, Progress, Typography, message } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faRotate, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'

import { Header } from 'components/Header'
import { FlexSpace } from 'components/FlexSpace'
import { FullLoader } from 'components/FullLoader'
import { Btn } from 'components/Btn'

import { RuleResult } from '../components/RuleResult'
import { SaveAction, SaveBtn } from '../components/SaveBtn'
import { ShareModal } from '../components/Share.modal'

import { api } from 'utils/axios'
import { currentCompany, currentCompliances } from 'recoil/companies.state'
import { ICompliance, IRuleResult } from 'types/compliance.types'
import { usePrompt } from 'hooks/useBlock'
import { stringToDate, toSafeDateString } from 'utils/dates'
import { IntegrationType } from 'types/company.types'
import { isPluginAuth } from 'utils/plugin'
import { currentUser } from 'recoil/user.state'

const { Title, Text } = Typography

interface Props {
  result?: ICompliance
  list: ICompliance[]
  onChange: (value: ICompliance) => void
}

export const ComplianceResult: ReactFC<Props> = (props) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const user = useRecoilValue(currentUser)
  const company = useRecoilValue(currentCompany)

  const refresh = useRecoilRefresher_UNSTABLE(currentCompliances)

  const { result, list, onChange } = props

  const [current, setCurrent] = useState<ICompliance>()
  const [loading, setLoading] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(false)
  const [share, setShare] = useState(false)


  const showSaveWindow = !result?.id && !loading // || !current?.finished
  const showSaveBtn = !result?.finished && !loading // || !current?.finished
  
  const isPluginInstalled = !!document.documentElement.hasAttribute('equility-extension')
  const isQBOConnected = !!company?.integrations.find(v => v.id === IntegrationType.QBO)?.status

  const viewOnly = company?.role.id === 2000

  const isShopify = !!result?.meta?.shopify

  usePrompt('Results are unsaved. Are you sure you want to leave?', showSaveWindow)

  useEffect(() => {
    if (id && id !== 'current' && list.length && !result) {
      const r = list.find((v) => v.id === parseInt(id))
      if (r) setCurrent(r)
    }
    if (id && id !== 'current' && !!result) setCurrent(result)
    if (id === 'current' && !result) onChange({} as any)
    if (id === 'current' && !!result) setCurrent(result)

    setLoading(false)
  }, [id, result, list])

  const handleUpdateResult = (result: IRuleResult) => {
    if (!current?.results) return
    if (current.finished) return

    const results = current.results.slice()

    const idx = result.rule.app_rule_id
      ? results.findIndex(v => result.rule.app_rule_id === v.rule.app_rule_id)
      : results.findIndex(v => result.rule.id === v.rule.id)
      
    results[idx] = result

    onChange({...current, results })
  }

  const handleSave = (action: SaveAction) => {
    if (action === SaveAction.Cancel) {
      setTimeout(() => {
        navigate('/company/' + company?.id + '/compliance/results')
      })
      return 
    }

    setLoading(true)
    const finished = current?.finished || action === SaveAction.Finish
    const payload = {...current, finished, application_id: company?.id }

    api.post<ICompliance>('/application_rules/compliance/', payload)
      .then(({ data }) => {
        onChange(data)
        setTimeout(() => {
          refresh()
          navigate('/company/' + company?.id + '/compliance/results')
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleRerun = async () => {
    if (!company || !current || !user) return

    if (!isPluginInstalled)
        return message.warning('Please install our Chrome Extension to take full advantage of Equility')
    if (!isQBOConnected)
      return message.warning('Please reconnect to QuickBooks')

    try {
      const from = toSafeDateString(current.checked_through_start)
      const to = toSafeDateString(current.checked_through_end)

      await isPluginAuth(user.email)

      const backurl = window.location.href.split('?')[0].replace('results/current', 'run') + `?daterange=${from}--${to}`
      const data = { type: "FROM_PAGE_TASK", company, backurl }

      localStorage.setItem('qbosync', JSON.stringify({ date: Date.now(), url: backurl }))
      localStorage.setItem(`copliance-${company.id}-${from}--${to}`, current.method || 'Cash')

      window.postMessage(data, "*")
    } catch (error) {
      message.warning('Please make sure that you are signed into the Equility plug-in')
    }
  }

  const handlePDF = () => {
    setPdfLoading(true)
    save()
      .then((r) => api.get(`/application_rules/${r?.id || current?.id}/pdf/`))   
      .then(({ data }) => window.open(data.file, '_blank'))
      .finally(() => setPdfLoading(false))
  }
  
  const handleShare = () => {
    setPdfLoading(true)
    save()
      .then(() => setShare(true))
      .finally(() => setPdfLoading(false))
  }

  const save = (options?: Partial<ICompliance>) => {
    const payload = {...current, ...options, application_id: company?.id }

    return api.post<ICompliance>('/application_rules/compliance/', payload)
      .then(({ data }) => {
        onChange({ id: data.id, ...current } as ICompliance)
        return data
      })
  }

  if (!current?.results) return <FullLoader />

  console.log('current: ', current)

  const from = stringToDate(current.checked_through_start.split('T')[0], 'll')
  const to = stringToDate(current.checked_through_end.split('T')[0], 'll')

  const total = current.results.length + (current.meta?.shopify?.length || 0)
  const shopifyPassed = current.meta?.shopify?.filter((v: any) => v.status === 'Passed').length || 0

  const passed = current.results.filter(v => v.status === 'Passed').length + shopifyPassed
  const progress = Math.round(100 / total * passed)

  const results = current.results.map(v => {
    const r = { ...v, rule: { ...v.rule } }
    if (!v.rule.id) 
      r.rule.id = r.rule.rule_id || 0

    return r
  })

  // balanceSheet

  const reconcile = results.filter(v => v.rule.id === 1)
  const undepositedFunds = results.filter(v => v.rule.id === 8)
  const deprication = results.filter(v => v.rule.id === 7)
  const uncatAsset = results.filter(v => v.rule.id === 18)
  const openBalance = results.filter(v => v.rule.id === 19)
  const equityArr = [6, 9, 12, 14, 15]
  const equity = results.filter(v => equityArr.includes(v.rule.id))
  const assetArr = [11, 13, 17]
  const asset = results.filter(v => assetArr.includes(v.rule.id))
  const liabilityArr = [10, 16]
  const liability = results.filter(v => liabilityArr.includes(v.rule.id))

  const balanceSheet = [...reconcile, ...undepositedFunds, ...deprication, ...uncatAsset, ...asset, ...liability, ...equity, ...openBalance]

  // incomeStatement
  const payrolsArr = [2, 3, 4]
  const payrols = results.filter(v => payrolsArr.includes(v.rule.id))
  const uncatTxs = results.filter(v => v.rule.id === 20)
  const incomeStatement = [...payrols, ...uncatTxs]

  return (
    <FlexSpace direction="vertical" style={{ paddingTop: 20 }}>
      <FlexSpace direction="horizontal" spacebetween style={{ marginBottom: -12 }}>
        <FlexSpace direction="vertical">
          <Header size={28} className="print">Compliance Check Results</Header>
          <Header size={28} className="hideprint">Results</Header>
          <FlexSpace size="small" direction="vertical">
            <Text strong className='print'>Company: {company?.alias || company?.name}</Text>
            <Text>Date Range: {from} - {to}</Text>
            <Text>Accounting method: {current.method || 'Cash'}</Text>
            <Text>{company?.entity?.title}</Text>
            {!viewOnly && (
              <FlexSpace>
                {showSaveBtn && <SaveBtn onClick={handleSave} loading={loading} finished={current.finished} />}
                {!showSaveBtn && loading && <Btn title="Save" loading />}
                <Btn
                  icon={<FontAwesomeIcon icon={faRotate} style={{ marginRight: 10 }} />} 
                  title="Rerun" 
                  style={{ minWidth: 100 }}
                  onClick={() => handleRerun()}
                />
                <Btn
                  icon={<FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 10 }} />} 
                  title="Download PDF" 
                  style={{ minWidth: 100 }}
                  onClick={handlePDF}
                  loading={pdfLoading}
                />
                <Btn
                  icon={<FontAwesomeIcon icon={faShareFromSquare} style={{ marginRight: 10 }} />} 
                  title="Send Report" 
                  style={{ minWidth: 100 }}
                  onClick={handleShare}
                  loading={pdfLoading}
                />
              </FlexSpace>
            )}
          </FlexSpace>
        </FlexSpace>
        <Progress 
          type="circle" 
          percent={progress} 
          format={(percent) => <span>{percent}%<br /><small style={{ fontSize: 16 }}>Accurate</small></span>} 
        />
      </FlexSpace>
      <Divider />
      <Title level={4}>BALANCE SHEET</Title> 
      <FlexSpace direction="vertical" size="large">
        {balanceSheet.map((v, i) => (
          <RuleResult item={v} to={to} key={v.id + '_' + i + i} onUpdate={handleUpdateResult} finished={current.finished} viewOnly={viewOnly} />
        ))}
      </FlexSpace>
      <Title level={4}>INCOME STATEMENT</Title> 
      <FlexSpace direction="vertical" size="large">
        {incomeStatement.map((v, i) => (
          <RuleResult item={v} to={to} key={v.id + '_' + i + i} onUpdate={handleUpdateResult} finished={current.finished} viewOnly={viewOnly} />
        ))}
      </FlexSpace>
      {isShopify && (
        <>
          <Title level={4}>SHOPIFY REVIEW</Title> 
          <FlexSpace direction="vertical" size="large">
            {current.meta.shopify.map((v: any, i: any) => (
              <RuleResult item={v} to={to} key={v.id + '_' + i + i} onUpdate={handleUpdateResult} />
            ))}
          </FlexSpace>
        </>
      )}
      <ShareModal open={share} setOpen={setShare} id={current?.id} data={{from, to}} />
    </FlexSpace>
  )
}
