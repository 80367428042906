import { IAccount } from 'types/company.types'

export function accountName(name: string, number: string) {
  return (number ? number + ' ' : '') + name
}

export function getAccountSubType(account: IAccount) {
  return (account.accountSubType.match(/[A-Z][a-z]+|[0-9]+/g) || []).join(' ')
}

export function getBankAccounts(accounts: IAccount[]) {
  const bank = accounts
    .filter((acc) => {
      // const subTypes = ['CashOnHand', 'Checking']
      const types = ['Bank']
      return types.includes(acc.accountType) // || subTypes.includes(acc.accountSubType)
    })
    .sort(sortAccounts) //.map(v => ({ ...v, isBank: true, isCredit: false }))

  const credit = accounts
    .filter((acc) => {
      const types = ['Credit Card']
      // const subTypes = ['CreditCard']
      return types.includes(acc.accountType) // || subTypes.includes(acc.accountSubType)
    })
    .sort(sortAccounts) //.map(v => ({ ...v, isBank: true, isCredit: true }))

  const fixedAsset = accounts
    .filter((acc) => {
      const types = ['Fixed Asset']
      return types.includes(acc.accountType)
    })
    .sort(sortAccounts) //.map(v => ({ ...v, isBank: false, isCredit: false }))

  const otherCurrentAsset = accounts
    .filter((acc) => {
      const types = ['Other Current Asset']
      return types.includes(acc.accountType)
    })
    .sort(sortAccounts) //.map(v => ({ ...v, isBank: false, isCredit: false }))

  const otherCurrentLiabilities = accounts
    .filter((acc) => {
      const types = ['Other Current Liability']
      return types.includes(acc.accountType)
    })
    .sort(sortAccounts) //.map(v => ({ ...v, isBank: false, isCredit: true }))

  const longTermLiabilities = accounts
    .filter((acc) => {
      const types = ['Long Term Liability']
      return types.includes(acc.accountType)
    })
    .sort(sortAccounts) //.map(v => ({ ...v, isBank: false, isCredit: true }))

  const equity = accounts
    .filter((acc) => {
      const types = ['Equity']
      return types.includes(acc.accountType)
    })
    .sort(sortAccounts)

  return [
    bank,
    credit,
    fixedAsset,
    otherCurrentAsset,
    otherCurrentLiabilities,
    longTermLiabilities,
    equity,
  ]
    .flat()
    .filter((v) => v.active && v.isReconcile)
}

function sortAccounts(a: IAccount, b: IAccount) {
  return a.name.localeCompare(b.name)
}

export const DATA_SOURCES = {
  QB: {
    key: 'QB',
    name: 'QuickBooks Online',
    children: [],
  },
  SP: {
    key: 'SP',
    name: 'Shopify (Accrual Only)',
    children: [],
  },
}

export const SHOPIFY_ACCOUNTS = [
  {
    key: 'SP-gross',
    id: 'gross',
    name: 'Gross Sales',
    children: [],
    type: 'Data',
  },
  {
    key: 'SP-discount',
    id: 'discount',
    name: 'Discounts',
    children: [],
    type: 'Data',
  },
  {
    key: 'SP-returns',
    id: 'returns',
    name: 'Returns',
    children: [],
    type: 'Data',
  },
  {
    key: 'SP-net',
    id: 'net',
    name: 'Net Sales',
    children: [],
    type: 'Data',
  },
  {
    key: 'SP-shipping',
    id: 'shipping',
    name: 'Shipping',
    children: [],
    type: 'Data',
  },
  {
    key: 'SP-tax',
    id: 'tax',
    name: 'Taxes',
    children: [],
    type: 'Data',
  },
  {
    key: 'SP-liability',
    id: 'liability',
    name: 'Gift Cards',
    children: [],
    type: 'Data',
  },
  {
    key: 'SP-total',
    id: 'total',
    name: 'Total',
    children: [],
    type: 'Data',
  },
  {
    key: 'SP-payments',
    id: 'payments',
    name: 'Payments',
    children: [],
    type: 'Data',
    shopify: true,
  },
]
