import {Page} from "../../components/Page";
import {Result} from "antd";

export const ErrorMessagePage = ({title = 'Error'}) => {
    return (
        <Page title={title}>
            <Result
                status="warning"
                title="Something gone wrong!"
                subTitle="Check your internet connection and try again"
            />
        </Page>
    )
}
