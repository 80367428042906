import { useEffect, useState } from 'react'
import { Divider, Flex, Spin, Tag, Typography, message, theme } from 'antd'

import { Btn } from 'components/Btn'
import { FlexSpace } from 'components/FlexSpace'
import { SelectAccounts } from '../components/SelectAccounts'
import { ProviderBtn } from '../components/ProviderBtn'

import { IProvider } from 'types/provider.type'
import { IAccount } from 'types/company.types'
import { ISetupData, addQboCompany, getAccounts, getCompany, revokeQboIntegration } from '../utils'

const { Text, Title } = Typography
const { useToken } = theme

interface StepsProps {
  data: ISetupData
  onSubmit: (data: ISetupData) => void
  onBack: () => void
  onChange: () => void
}

export const AccountingStep = (props: StepsProps) => {
  const { data, onSubmit, onChange, onBack } = props

  const { token } = useToken()

  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState<ISetupData>(data)
  const [accounts, setAccounts] = useState<IAccount[]>([])
  const [accLoading, setAccLoading] = useState(false)

  const status = company?.integrations?.find((i) => i.name === 'QBO')?.status || 0

  useEffect(() => {
    if (!company?.id) return
    setAccLoading(true)
    getAccounts(company.id)
      .then(setAccounts)
      .finally(() => setAccLoading(false))
  }, [company.id])

  const onClick = (p: IProvider) => {
    setLoading(true)
    addQboCompany()
      .then((r: any) => {
        const { id, name } = r // TODO: const { isPresent, id, name } = r
        setCompany({ ...data, id, name })
        return getCompany(id).then((c) => setCompany(c))
      })
      .catch((err) => {
        console.log('Error', err)
        message.error(err || 'Unknown error', 5)
      })
      .finally(() => setLoading(false))
      .finally(() => onChange())
  }

  const onChangeAccounts = (value: string[]) => {
    const settings = { ...company.settings, uncat: { selected: value } }
    setCompany({ ...company, settings })
  }

  const onDisconnect = () => {
    if (!data?.id) return
    const { id } = data
    setLoading(true)
    revokeQboIntegration(id)
      .then(() => {
        message.info('QuickBooks Access revoked for ' + data?.name)
        return getCompany(id).then((c) => setCompany(c))
      })
      .catch((err) => message.error(err || 'Unknown error', 5))
      .finally(() => setLoading(false))
  }

  // console.log('AccountingStep', data)
  // console.log('company', company)

  if (company?.id) {
    return (
      <FlexSpace direction="vertical" size="large">
        <FlexSpace spacebetween style={{ alignItems: 'baseline' }}>
          <Title level={5}>
            <FlexSpace size="large">
              <span>{company.name}</span>
              {status === 0 && <Tag color={token.colorError}>disconnected</Tag>}
              {status === 1 && <Tag color={token.colorSuccess}>active</Tag>}
              {status === 2 && <Tag color={token.colorWarning}>syncing...</Tag>}
            </FlexSpace>
          </Title>
          <FlexSpace>
            <Btn
              title="Reconnect" 
              onClick={() => onClick(providers[0])}
              type={status === 0 ? 'primary' : 'default'}
            />
            {status === 1 && (
              <Btn
                title="Disconnect"
                loading={loading} 
                onClick={onDisconnect} 
                tooltip="This will revoke access to your QuickBooks account"
              />
            )}
          </FlexSpace>
        </FlexSpace>
        {status === 0 && (
          <Text>
            Please connect your accounting platform to start using our services.<br /><br />
            <i>We will not make any modifications to
            your accounting platform. <br />Our integrations are needed to pull financial data and our access can be revoked at
            any time.</i>
          </Text>
        )}
        {status !== 0 && (
          <FlexSpace direction="vertical" size="large">
            <Text>
              We need to know how you use your general ledger accounts to better categorize your transactions.
            </Text>
            <Text>
              What general ledger accounts do you use as clearing accounts (ie, Ask My Accountant, Uncategorized Income,
              Uncategorized Expense)?
            </Text>
            <SelectAccounts
              value={company.settings?.uncat?.selected ?? []}
              accounts={accounts}
              onChange={onChangeAccounts}
              loading={accLoading}
            />
          </FlexSpace>
        )}
        <Divider />
        <FlexSpace>
          <Btn title="Back" onClick={onBack} />
          <Btn type="primary" title="Continue" onClick={() => onSubmit(company)} />
        </FlexSpace>
      </FlexSpace>
    )
  }

  return (
    <FlexSpace direction="vertical" size="large">
      <Title level={5}>Connect your accounting​ platform</Title>

      <Text>
        Please remember that we cannot and will not make any modifications to your apps. Our integrations are needed to
        pull financial data and our access can be revoked at any time.
      </Text>

      <Spin spinning={loading}>
        <Flex wrap="wrap" gap="small">
          {providers.map((p) => (
            <ProviderBtn onClick={() => onClick(p)} data={p} key={p.id} />
          ))}
        </Flex>
      </Spin>

      <Divider />

      <FlexSpace>
        <Btn title="Back" onClick={onBack} />
        <Btn type="primary" title="Skip" onClick={() => onSubmit(data)} />
      </FlexSpace>
    </FlexSpace>
  )
}

const providers: IProvider[] = [
  {
    id: 'QUICKBOOKS',
    title: 'QuickBooks Online',
    logo: 'https://digitalasset.intuit.com/content/dam/intuit/qb-design/brand/brand-foundations/logo/qb-logo-preferred-and-alternate-photo.svg',
    active: true,
    imageStyle: { height: 90, marginLeft: 125, marginTop: -12 },
  },
  {
    id: 'XERO',
    title: 'Xero',
    logo: 'https://www.optimalworkshop.com/wp-content/uploads/2023/03/Xero_logo.svg',
    active: false,
    imageStyle: { height: 30, marginLeft: 0, marginTop: 0, opacity: 0.5 },
  },
]
