import React, {useEffect, useState} from 'react';
import {Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd';
import {useRecoilValue} from "recoil";
import {companiesAtom} from "../recoil/companies.state";
import {api} from "../utils/axios";
import dayjs from "dayjs";
import {
    getProjectAccountingPeriodString,
    getProjectRecurringStatusString, getProjectStatusString,
    PROJECT_ACCOUNTING_PERIOD_ENUM,
    PROJECT_RECURRING_ENUM, PROJECT_STATUSES_ENUM,
} from "../types/todo.types";
import {Btn} from "./Btn";
import {IUser} from "../types/user.types";
import {FlexSpace} from "./FlexSpace";
import {OptionType} from "./ToDoFilter";

interface AddEditTodoComponentProps {
    isVisible: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    title: string;
    isEditMode?: boolean;
    initialData?: any;
}

const {Option} = Select;


export const AddProject: React.FC<AddEditTodoComponentProps> = ({
                                                                    isVisible,
                                                                    handleOk,
                                                                    handleCancel,
                                                                    title,
                                                                    isEditMode = false,
                                                                    initialData
                                                                }) => {
    const [form] = Form.useForm();
    const companies = useRecoilValue(companiesAtom);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined | null>();
    const [members, setMembers] = useState<OptionType[]>([]);
    const [taskName, setTaskName] = useState<string>('');
    const [disableFrequency, setDisableFrequency] = useState<boolean>(false);

    const recurringOptions = Object.keys(PROJECT_RECURRING_ENUM)
        .filter(key => isNaN(Number(key)))
        .map(key => {
            return {
                label: key.replace(/_/g, ' '),
                value: key
            };
        });

    const membersList: any = members
        .map((v) => ({
            name: v.label,
            value: v.value,
            label: v.label,
        }))

    const companiesList: any = companies
        .map((v) => ({
            name: v.alias || v.name,
            value: v.id,

            label: (
                <FlexSpace >
                    <span>{v.alias || v.name}</span>
                </FlexSpace>
            )
        }))

    const accountingPeriodOptions = Object.keys(PROJECT_ACCOUNTING_PERIOD_ENUM)
        .filter(key => isNaN(Number(key)))
        .map(key => {
            return {
                label: key.replace(/_/g, ' '),
                value: key
            };
        });


    const todoStatusOptions = Object.keys(PROJECT_STATUSES_ENUM)
        .filter(key => isNaN(Number(key)))
        .map(key => {
            return {
                label: key.replace(/_/g, ' '),
                value: key
            };
        });

    useEffect(() => {

        if (isVisible && isEditMode && initialData) {
            form.setFieldsValue({
              title: initialData.name,
              application: initialData.application?.id ? {...initialData.application, value: initialData.application?.id} : null,
              user: initialData.user ? {...initialData.user, value: initialData.user?.id} : null,
              dueDate: initialData.dueDate ? dayjs(initialData.dueDate) : null,
              startDate: initialData.startDate ? dayjs(initialData.startDate) : null,
              recurring: initialData.recurring && getProjectRecurringStatusString(initialData.recurring),
              accounting_period: initialData.accounting_period && getProjectAccountingPeriodString(initialData.accounting_period),
              status: getProjectStatusString(initialData.status),
          });
            setDisableFrequency(getProjectRecurringStatusString(initialData.recurring) === 'No')
            setSelectedCompanyId(initialData.application?.id);
        } else if (isVisible && initialData) {
            form.setFieldsValue({
                recurring: initialData.recurring,
                status: initialData.status,
            });
            setSelectedCompanyId(initialData.application);
        } else if (!isVisible) {
            setDisableFrequency(false);
            setMembers([]);
            form.resetFields();
        }
    }, [isVisible, isEditMode, initialData]);

    useEffect(() => {
        if (selectedCompanyId) {
            fetchMembers(selectedCompanyId);
        }
    }, [selectedCompanyId]);


    const onFormFinish = async (values: any) => {
        let response
        if (isEditMode) {
            const {id} = initialData || {};
            const editBody = {
                name: values.title,
                recurring: values.recurring && PROJECT_RECURRING_ENUM[values.recurring],
                accounting_period: values.accounting_period && PROJECT_ACCOUNTING_PERIOD_ENUM[values.accounting_period],
                startDate: values.startDate,
                dueDate: values.dueDate,
                application: values.application?.id ? values.application.id : values.application,
                user: values.user?.id ? values.user.id : values.user,
                status: PROJECT_STATUSES_ENUM[values.status],
            }
            response = await api.patch(`/project/${id}`, editBody)
        } else {
            const body = {
                name: values.title,
                recurring: PROJECT_RECURRING_ENUM[values.recurring],
                accounting_period: PROJECT_ACCOUNTING_PERIOD_ENUM[values.accounting_period],
                startDate: values.startDate,
                dueDate: values.dueDate,
                application: selectedCompanyId,
                user: values.user,
                status: PROJECT_STATUSES_ENUM[values.status],
            }
            response = await api.post('/project', body)
        }


        if (response.status) {
            handleOk();
        }

    };

    const fetchMembers = async (companyId: string) => {
        try {
            const { data } = await api.get(`/applications/${companyId}/members`);
            const newMembers = data.map((member: IUser) => ({
                ...member,
                value: member.id,
                label: member.firstName + ' ' + member.lastName
            }))
            setMembers(newMembers);

        } catch (error) {
            console.error('Error fetching members:', error);
            // Handle error appropriately
        }
    };

    // const disabledStartDate = (current: dayjs.Dayjs) => {
    //     return current && current.isBefore(dayjs().startOf('day'));
    // };

    // const disabledDueDate = (current: dayjs.Dayjs) => {
    //     const startDate = form.getFieldValue('startDate');
    //     return startDate ?  current.isBefore(startDate, 'day') : current.isBefore(dayjs().startOf('day'));
    // };

    return (
        <Modal
            title={title}
            open={isVisible}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            width={720}
            footer={[
                <Btn key="back" onClick={handleCancel}>
                    Cancel
                </Btn>,
                <Btn key="submit" type="primary" onClick={() => form.submit()}>
                    {isEditMode ? 'Update' : 'Create'}
                </Btn>,
            ]}

        >
            <Form form={form}
                  layout="vertical"
                  onFinish={onFormFinish}
            >
                <Row gutter={16}>
                    <Col span={12}>

                        <Form.Item label="Project Name"
                                   name="title"
                                   rules={[{required: true, message: 'Please enter project name!'}]}>
                            <Input
                                value={taskName}
                                onChange={e => setTaskName(e.target.value)}
                                placeholder="Name"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>

                        <Form.Item label="Client"
                                   name='application'>
                            <Select
                                showSearch
                                className="SelectPrimary"
                                placeholder="Select an option"
                                onChange={(value: string) => {
                                    if (value) {
                                        setSelectedCompanyId(value);
                                    } else {
                                        setMembers([]);
                                        setSelectedCompanyId(null);
                                    }
                                    form.setFieldsValue({ user: null })
                                }}
                                filterOption={(input, option) =>
                                    option?.name?.toString()?.toLowerCase().includes(input.toLowerCase())
                                }
                                options={companiesList}
                                allowClear
                            />
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Start Date"
                                   name="startDate">
                            <DatePicker
                                placeholder="Optional"
                                style={{width: '100%'}}
                                // disabledDate={disabledStartDate}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Due Date"
                                   name="dueDate">
                            <DatePicker
                                placeholder="Optional"
                                style={{width: '100%'}}
                                // disabledDate={disabledDueDate}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Recurring"
                                   name="recurring">
                            <Select
                                className="SelectPrimary"
                                showSearch
                                onChange={(value: string, option: any) => {
                                    setDisableFrequency(value === 'No')
                                    form.setFieldsValue({ accounting_period: null })
                                }}
                                placeholder="Select an Option">
                                {recurringOptions.map(option => (
                                    <Option key={option.label} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Recurring Frequency"
                                   name="accounting_period">
                            <Select
                                showSearch
                                disabled={disableFrequency}
                                className="SelectPrimary"
                                placeholder="Select an Option">
                                {accountingPeriodOptions.map(option => (
                                    <Option key={option.label} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={16}>
                    <Col span={12}>

                        <Form.Item label="Assignee"
                                   name="user">
                            <Select
                                className="SelectPrimary"
                                showSearch
                                placeholder="Select a member"
                                filterOption={(input, option) =>
                                    option?.name?.toString()?.toLowerCase().includes(input.toLowerCase())
                                }
                                allowClear
                                options={membersList}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Status"
                                   name="status">
                            <Select
                                className="SelectPrimary"
                                showSearch
                                placeholder="Select a status">
                                {todoStatusOptions.map(option => (
                                    <Option key={option.label} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Modal>
    );
};

