import { useEffect, useState } from 'react'
import { Input } from 'antd'
import IMask from 'imask'

interface Props {
  value?: number
  onChange: (value?: number) => void
}

export const NumberInput: ReactFC<Props> = (props) => {
  const { value, onChange } = props

  const [current, setCurrent] = useState<any>()

  useEffect(() => {
    value 
      ? setCurrent(formatCurrent(MASKED.resolve(value + '')))
      : value === 0
        ? setCurrent('0.00')
        : setCurrent(undefined)
  }, [value])

  const handleChange = (e: any) => {
    const v = e.target.value
    if (!v) onChange(v)

    const isDemical = v.includes('.')
    let nextValue = v

    if (nextValue === '-') 
      return setCurrent('-')

    if (isDemical) {
      const valueSplit = v.split('.')
      nextValue = MASKED.resolve(valueSplit[0]) + '.' + (valueSplit[1] || '')
    } else {
      nextValue = MASKED.resolve(v)
    }

    setCurrent(nextValue)
  }

  const handleBlur = (e: any) => {
    if (!current) return
    setCurrent(formatCurrent(current))
    onChange(parseFloat(current.replaceAll(',', '')))
    e?.relatedTarget?.click()
  }

  return (
    <Input
      prefix="$"
      step=".01"
      placeholder="0.00"
      value={current}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={(event) => {
        const value = event.currentTarget.value
        const valueSplit = value.split('.')
        const isDemical = value.includes('.') || !value.length

        if (value.length && event.key === '-') event.preventDefault()
        if (!/[0-9]/.test(event.key) && event.key !== '.' && event.key !== '-') event.preventDefault()
        else if (isDemical && event.key === '.') event.preventDefault()
        else if (valueSplit[1]?.length > 1) event.preventDefault()
      }}
      allowClear
      style={{ width: 220 }}
    />
  )
}

const MASKED = IMask.createMask({
  mask: Number, // enable number mask
  scale: 2, // digits after point, 0 for integers
  thousandsSeparator: ',', // any single char
  padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
  radix: '.', // fractional delimiter,
  signed: true,
})

function formatCurrent(current: string) {
  const isDemical = current.includes('.')
  const valueSplit = current.split('.')

  if (!isDemical) return current + '.00'

  const decimal = (valueSplit[1] + '00').slice(0, 2)
  const nextValue = valueSplit[0] + '.' + decimal

  return nextValue
}