const THUMB_POSTFIX = '_thumb.'

export const getImageThumb = (image: string | null | undefined): string | null => {
  if (image) {
    let imageArr = image.split('.')
    let imageThumb = imageArr.slice(0, -1).join('.') + THUMB_POSTFIX + imageArr[imageArr.length - 1]
    return imageThumb
  } else {
    return null
  }
}


export function getFileNameFromUrlWithQuery(url: string) {
  if (!url) {
    return  '';
  }
  const urlWithoutQuery = url.split('?')[0];
  const parts = urlWithoutQuery.split('/');
  return parts[parts.length - 1];
}
