import {useEffect, useState} from 'react'
import {message, Spin, Table} from 'antd'
import {FlexSpace} from 'components/FlexSpace'
import {HeadTitle} from 'components/HeadTitle'
import {Page} from 'components/Page'
import {Block} from 'components/Block'

import {
    Project,
} from 'types/todo.types'
import {api} from "../../utils/axios";
import {errorMsg} from 'utils/errorMsg'

import {ErrorMessagePage} from "../errorMessage/ErrorMessage.page";
import {useNavigate} from "react-router-dom";
import {Btn} from "../../components/Btn";

export const TemplatesPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [projectList, setProjectList] = useState<Project[]>([]);
    const [error, setError] = useState(false)


    const columns: any = [
        {
            title: 'Title',
            width: '90%',
            render: (row: Project) => {
                const {name} = row || {}

                return <FlexSpace style={{flexDirection: 'column', alignItems: 'start'}}>
                    <span>{name}</span>
                </FlexSpace>

            }
        },
        {
            title: '',
            width: '10%',
            key: 'actions',
            render: (row: Project) => (
                <Btn danger onClick={(e) => handleDelete(e,row.id)}>
                    Delete
                </Btn>
            ),
        },
    ]

    useEffect(() => {
        getTemplateList();
    }, [])

    const handleDelete = async (e: any,id: number) => {
        e.stopPropagation()
        await api.delete(`/project/${id}`);
        getTemplateList();
    };

    const getTemplateList = () => {
        setLoading(true);


        const params = {
            type: 1
        }
        return api.get<{ count: number, data: Project[] }>('/project', {params})
            .then(({data}) => {
                setProjectList(data.data)
                setError(false)
                setLoading(false);
            })
            .catch((err) => {
                message.error(errorMsg(err), 5)
                setLoading(false);
            })
    }


    if (error) return <ErrorMessagePage title='Templates'/>

    return (
        <Page title={'Templates'}>
            <HeadTitle title="Templates"/>
            <FlexSpace direction="vertical" size="large">
                <Block className="FullTable">
                    <Spin spinning={loading}>

                                <Table
                                    className="RecTable Table"
                                    dataSource={projectList}
                                    columns={columns}
                                    pagination={false}
                                    rowKey={'id'}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => {
                                                navigate(`/projects/${record.id}`)
                                            },
                                        };
                                    }}

                                />
                    </Spin>
                </Block>

            </FlexSpace>
        </Page>
    )
}

