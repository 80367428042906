import React, { useState } from 'react';
import { Modal, Table, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {useRecoilValue} from "recoil";
import {companiesAtom} from "../recoil/companies.state";
import {ICompany} from "../types/company.types";
import {FlexSpace} from "./FlexSpace";


interface ClientSelectionModalProps {
    visible: boolean;
    onOk: (selectedKeys: string[]) => void;
    onCancel: () => void;
}

export const ClientSelectionModal: React.FC<ClientSelectionModalProps> = ({ visible, onOk, onCancel }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const companies = useRecoilValue(companiesAtom)

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys as string[]);
    };

    const handleOk = () => {
        onOk(selectedRowKeys);
        setSelectedRowKeys([]);
    };

    const columns: ColumnsType<ICompany> = [
        {
            title: 'Client Name',
            render: (row: ICompany) => {
                const {alias, name} = row || {};
                return <FlexSpace>
                    {alias || name}
                </FlexSpace>

            }
        },
    ];

    return (
        <Modal
            title="Select Clients"
            open={visible}
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} disabled={selectedRowKeys.length === 0}>
                    Next
                </Button>,
            ]}
        >
            <Table<ICompany>
                pagination={false}
                rowKey='id'
                rowSelection={{type:'radio',
                    selectedRowKeys,
                    onChange: onSelectChange
                }} columns={columns} dataSource={companies} />
        </Modal>
    );
};

