import React, {useEffect, useState} from 'react';
import {Col, DatePicker, Dropdown, Form, Input, message, Modal, Row, Select, Upload} from 'antd';
import {api} from "../utils/axios";
import dayjs from "dayjs";
import {getToDoStatusString, Project, TODO_STATUSES_ENUM} from "../types/todo.types";
import {Btn} from "./Btn";
import {IUser} from "../types/user.types";
import {EllipsisOutlined} from '@ant-design/icons';
import {FlexSpace} from "./FlexSpace";
import {useParams} from "react-router-dom";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {CommentSection} from "./CommentSection";
import {UploadOutlined} from '@ant-design/icons';
import {errorMsg} from "../utils/errorMsg";
import {UploadChangeParam, UploadFile} from "antd/es/upload/interface";
import {getFileNameFromUrlWithQuery} from "../utils/images";
import {ICompany} from "../types/company.types";

interface AddEditTodoComponentProps {
    isVisible: boolean;
    allTasks?: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    title: string;
    isEditMode?: boolean;
    initialData?: any;
    project?: Project | null
    projectList?: Project[],
}

const {Option} = Select;


const AddEditTodo: React.FC<AddEditTodoComponentProps> = ({
                                                              isVisible,
                                                              allTasks,
                                                              handleOk,
                                                              handleCancel,
                                                              title,
                                                              isEditMode = false,
                                                              initialData,
                                                              project,
                                                              projectList
                                                          }) => {

    const {id} = useParams()
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [members, setMembers] = useState<IUser[]>([]);
    const [taskName, setTaskName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [draftValue, setDraftValue] = useState<EditorState>(EditorState.createEmpty());
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileUrls, setFileUrls] = useState<string[]>([]);
    const [selectedProject, setSelectedProject] = useState<{ value: number, name: string, application: ICompany } | null>(null);

    const projectListOptions: any = projectList?.map((v) => ({
            name: v.name,
            value: v.id,
            application: v.application,

            label: (
                <FlexSpace>
                    <span>{v.name}</span>
                </FlexSpace>
            )
        }))

       const membersOptions: any = members?.map((member) => ({
            name: member.firstName + '' + member.lastName,
            value: member.id,

            label: (
                <FlexSpace>
                    <span>{member.firstName} {member.lastName}</span>
                </FlexSpace>
            )
        }))

    const deleteTodo = async () => {
        const {id} = initialData || {};
        const params = {
            applicationId: project?.application?.id
        }
        const response = await api.post(`/to-do/${id}/delete`, {params});

        if (response.status) {
            handleOk();
        }

    }

    const menuProps = {
        items: [{
            label: 'Delete',
            key: '1',
            danger: true,
            onClick: deleteTodo
        },],
    };

    const todoStatusOptions = Object.keys(TODO_STATUSES_ENUM)
        .filter(key => isNaN(Number(key)))
        .map(key => {
            return {
                label: key.replace(/_/g, ' '),
                value: key
            };
        });

    useEffect(() => {
        if (isVisible && isEditMode && initialData) {
            form.setFieldsValue({
                title: initialData.title,
                application: {...initialData?.application, value: initialData.application?.id},
                user: {...initialData.user, value: initialData.user?.id},
                dueDate: initialData.dueDate ? dayjs(initialData.dueDate) : null,
                status: getToDoStatusString(initialData.status),
            });

            if (initialData.description) {
                setDescription(initialData.description);
                const contentBlock = htmlToDraft(initialData.description);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setDraftValue(editorState);
                }
            }
            if (initialData.files?.length) {
                setFileUrls(initialData.files);
                const list = initialData.files.map((item: string, index: number) => ({
                    url: item,
                    uid: index,
                    name: getFileNameFromUrlWithQuery(item),
                    status: 'done'
                }))
                setFileList(list);
            }
        } else if (isVisible && initialData) {
            form.setFieldsValue({
                title: initialData.title,
                application: initialData.application,
                user: initialData.user,
                status: initialData.status,
            });
        } else if (!isVisible) {
            form.resetFields();
            setDraftValue(EditorState.createEmpty());
            setFileList([]);
            setFileUrls([]);
        }
    }, [isVisible, isEditMode, initialData]);

    useEffect(() => {
        if (project?.application?.id) {
            fetchMembers(project?.application?.id);
        }
    }, [project?.application?.id]);

    useEffect(() => {
        if (selectedProject?.application.id) {
            fetchMembers(selectedProject?.application?.id);
        }  else {
            setMembers([]);
        }
    }, [selectedProject?.application.id]);


    const onFormFinish = async (values: any) => {
        setSubmitting(true);
        let response;
        const newFiles = fileList.filter((file: UploadFile) => file.originFileObj);
        const promises = newFiles.map(file => {
            const formData = new FormData();
            file.originFileObj && formData.append('file', file.originFileObj);
            return api.post(`comments/file-upload`, formData)
                .then(res => res.data.url)
                .catch(err => {
                    message.error(errorMsg(err), 5);
                    return null;
                });
        });
        const results = await Promise.all(promises);
        const uploadedUrls = results.filter(url => url !== null);

        const allFileUrls = [...fileUrls, ...uploadedUrls];
        if (isEditMode) {
            const {id} = initialData || {};
            const editBody = {
                params: {
                    applicationId: project?.application?.id
                },
                todoItem: {
                    description: description,
                    title: values.title,
                    user: values.user.id ? values.user.id : Number(values.user) ? values.user : null,
                    dueDate: values.dueDate,
                    status: TODO_STATUSES_ENUM[values.status],
                    files: allFileUrls.length ? allFileUrls : undefined
                }
            }
            response = await api.patch(`/to-do/${id}`, editBody);
        } else {
            const body = {
                project: selectedProject ? selectedProject.value : Number(id),
                application: project?.application?.id || selectedProject?.application?.id,
                description: description,
                status: TODO_STATUSES_ENUM[values.status],
                title: values.title,
                user: values.user,
                dueDate: values.dueDate,
                files: allFileUrls.length ? allFileUrls : undefined
            }
            response = await api.post('/to-do', body)
                .finally(() => setSubmitting(false))
        }


        if (response.status) {
            handleOk();
            setSubmitting(false);
        } else {
            message.error(errorMsg('Something gone wrong!'), 5);
        }
            setSubmitting(false);

    };

    const fetchMembers = async (companyId: number | string) => {
        try {
            const response = await api.get(`/applications/${companyId}/members`);
            setMembers(response.data);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    const handleChange = async (info: UploadChangeParam<UploadFile>) => {
        const {file, fileList: newFileList} = info;
        setFileList(newFileList);

        if (file.status === 'removed') {
            setFileUrls((prevUrls: any) => prevUrls.filter((url: any) => url !== file.url));
        }
    };


    return (
        <Modal
            getContainer={() => document.getElementById("root") as HTMLElement}
            title={title}
            open={isVisible}
            destroyOnClose={true}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            width={1080}
            footer={[
                <Btn key="back" onClick={handleCancel}>
                    Cancel
                </Btn>,
                <Btn key="submit" type="primary"
                     loading={submitting}
                     onClick={() => form.submit()}>
                    {isEditMode ? 'Update' : 'Create'}
                </Btn>,
            ]}

        >
            {isEditMode && <FlexSpace style={{justifyContent: 'end', marginRight: 24}}>
              <Dropdown menu={menuProps}
                        trigger={['click']}
                        placement="bottomRight"
              >
                <EllipsisOutlined style={{fontSize: '22px', transform: 'rotate(90deg)'}}/>
              </Dropdown>
            </FlexSpace>}
            <Form form={form}
                  layout="vertical"
                  onFinish={onFormFinish}
            >
                <Form.Item label="Enter Task Name"
                           name="title"
                           rules={[{required: true, message: 'Please enter the task name!'}]}>
                    <Input
                        value={taskName}
                        onChange={e => setTaskName(e.target.value)}
                        placeholder="Enter Task Name"
                    />
                </Form.Item>


                <Row gutter={16}>

                    {!!allTasks &&
                      <Col span={12}>

                        <Form.Item label="Project"
                                   name='project'
                                   rules={[{required: true, message: 'Please select project!'}]}>
                          <Select
                            className="SelectPrimary"
                            placeholder="Select an option"
                            onChange={(value: string, option: any) => {
                                setSelectedProject(option)
                                form.setFieldsValue({ user: null })
                                if (option?.application?.id) {
                                    setMembers([]);
                                }
                            }}
                            allowClear
                            showSearch
                            options={projectListOptions}
                            filterOption={(input, option) =>
                                option?.name?.toLowerCase().includes(input.toLowerCase())
                            }
                          >
                          </Select>
                        </Form.Item>
                      </Col>
                    }
                    <Col span={12}>
                        <Form.Item label="Assignee"
                                   name="user"
                                   className="SelectPrimary">
                            <Select
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option?.name?.toLowerCase().includes(input.toLowerCase())
                                }
                                options={membersOptions}
                                placeholder="Select a member">
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Due Date"
                                   name="dueDate">
                            <DatePicker
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Status"
                                   name="status">
                            <Select
                                className="SelectPrimary"
                                showSearch

                                placeholder="Select a status">
                                {todoStatusOptions.map(option => (
                                    <Option key={option.label} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Form.Item label="Description"
                           name="description">
                    <DocumentEditor onDescChange={(value: string) => setDescription(value)}
                                    setDraftValue={setDraftValue} draftValue={draftValue}/>
                </Form.Item>
            </Form>

            <FlexSpace style={{marginBottom: 12}}>
                <Upload
                    beforeUpload={() => false}
                    listType="picture"
                    fileList={fileList}
                    onChange={handleChange}>
                    <Btn icon={<UploadOutlined/>}>Click to Upload</Btn>
                </Upload>
            </FlexSpace>
            {isEditMode && <CommentSection entityId={initialData?.id} entityType='todo'></CommentSection>}
        </Modal>
    );
};

export default AddEditTodo;


const DocumentEditor = ({onDescChange, draftValue, setDraftValue}: any) => {

    const onEditorStateChange = (editorState: EditorState) => {
        setDraftValue(editorState);
        const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        onDescChange(value);
    };

    return (
        <Editor
            editorState={draftValue}
            toolbarClassName='toolbarClassName'
            wrapperClassName='wrapperClassName'
            editorClassName='editorClassName'
            editorStyle={editorStyle}
            onEditorStateChange={onEditorStateChange}
        />
    );
};


const editorStyle: React.CSSProperties = {
    minHeight: '250px',
    padding: '0 20px',
    background: '#ffffff',
    border: '1px solid #d9d9d9',
    borderRadius: '6px',
    wordSpacing: '-1px'
}
