import React, { useEffect, useState } from 'react';
import {Form, Select} from 'antd';
import { useRecoilValue } from 'recoil';
import { companiesAtom } from '../recoil/companies.state';
import { api } from '../utils/axios';
import { IUser } from '../types/user.types';
import { useForm } from 'antd/es/form/Form';
import { FlexSpace } from './FlexSpace';
import {OptionType} from "./ToDoFilter";

interface ProjectFiltersProps {
  onCompanyChange: (companyId: string[]) => void;
  onStatusChange: (statusId: string[]) => void;
  onMemberChange: (memberIds: string[]) => void;
  selectedMemberIds: string[];
}

export const ProjectFilters = ({ onCompanyChange, onMemberChange, selectedMemberIds, onStatusChange }: ProjectFiltersProps) => {
  const [form] = useForm();
  const companies = useRecoilValue(companiesAtom);
  const [members, setMembers] = useState<OptionType[]>([]);
  const statuses: OptionType[] = [
    { label: 'New', value: 1 },
    { label: 'In progress', value: 2 },
    { label: 'Completed', value: 3 },
    { label: 'On hold', value: 4 },
    { label: 'Canceled', value: 5 },
  ];

    const companiesList: any = companies
        .map((v) => ({
            name: v.alias || v.name,
            value: v.id,

            label: (
                <FlexSpace >
                    <span>{v.alias || v.name}</span>
                </FlexSpace>
            )
        }))

    const membersList: any = members
        .map((v) => ({
            name: v.label,
            value: v.value,

            label: (
                <FlexSpace >
                    <span>{v.label}</span>
                </FlexSpace>
            )
        }))

    console.log(membersList, 'membersList')
    const statusesList: any = statuses
        .map((v) => ({
            name: v.label,
            value: v.value,

            label: (
                <FlexSpace>
                    <span>{v.label}</span>
                </FlexSpace>
            )
        }))

    useEffect(() => {
      fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
        const {data} = await api.get(`/applications/members`);
        const newMembers = (data || []).map((member: IUser) => ({...member, value: member.id, label: member.firstName + ' ' + member.lastName}))
        newMembers.unshift({
            label: <span>Select All</span>,
            name: 'Select All',
            value: 'All'
        })
       setMembers(newMembers);
    } catch (error) {
      console.error('Error fetching members:', error);
      // Handle error appropriately
    }
  };

  const onSelect = (e: string) =>{
      const allMemberIds = members.map((item) => item.value)
      if (e === 'All') {
          onMemberChange(allMemberIds);
          form.setFieldsValue({user: allMemberIds});
      } else {
          onMemberChange([...selectedMemberIds, e]);
          form.setFieldsValue({user : [...selectedMemberIds, e]
      });
      }
    }

    const onDeselect = (e: any) => {
      if (e === 'All') {
          onMemberChange([]);
          form.setFieldsValue({ user: [] });
      } else {
          const unselected = selectedMemberIds.filter((item) => item !== e);
          onMemberChange(unselected)
          form.setFieldsValue({ user: unselected });
      }
    }

  return (
      <Form form={form} layout="vertical">
        <FlexSpace>
          <Form.Item label="Filter by Company" name="application">
              <Select
                  className="SelectPrimary"
                  maxTagCount={1}
                  placeholder="Select a company"
                  style={selectStyle}
                  onChange={(value) => {
                      onCompanyChange(value);
                  }}
                  filterOption={(input, option) =>
                      option?.name?.toLowerCase().includes(input.toLowerCase())
                  }
                  allowClear
                  showSearch
                  options={companiesList}
                  mode="multiple"
              />
          </Form.Item>

          <Form.Item label="Filter by Assignee" name="user">
              <Select
                  className="SelectPrimary"
                  maxTagCount={1}
                  placeholder="Select members"
                  style={selectStyle}
                  onChange={(values) => {
                      onMemberChange(values);
                  }}
                  filterOption={(input, option) =>
                      option?.name?.toString()?.toLowerCase().includes(input.toLowerCase())
                  }
                  onSelect={onSelect}
                  onDeselect={onDeselect}
                  value={selectedMemberIds}
                  allowClear
                  showSearch
                  options={membersList}
                  mode="multiple"
              />
          </Form.Item>

          <Form.Item label="Filter by Status" name="status">
              <Select
                  className="SelectPrimary"
                  maxTagCount={1}
                  placeholder="Select status"
                  style={selectStyle}
                  onChange={(values) => {
                      onStatusChange(values);
                  }}
                  filterOption={(input, option) =>
                      option?.name?.toLowerCase().includes(input.toLowerCase())
                  }
                  allowClear
                  showSearch
                  options={statusesList}
                  mode="multiple"
              />
          </Form.Item>

        </FlexSpace>
      </Form>
  );
};

const selectStyle: React.CSSProperties = {
    width: 300,
}
