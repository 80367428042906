import React, {useEffect, useState} from 'react'
import {Divider, message, Spin, Table, TablePaginationConfig, Tabs, Typography} from 'antd'
import {Btn} from 'components/Btn'
import {FlexSpace} from 'components/FlexSpace'
import {HeadTitle} from 'components/HeadTitle'
import {Page} from 'components/Page'
import {Block} from 'components/Block'

import {
    getToDoStatusString,
    Project,
    PROJECT_ACCOUNTING_PERIOD_ENUM,
    PROJECT_RECURRING_ENUM,
    TODO_STATUSES_ENUM, ToDoResponse
} from 'types/todo.types'
import {api} from "../../utils/axios";
import {errorMsg} from 'utils/errorMsg'

import {ErrorMessagePage} from "../errorMessage/ErrorMessage.page";
import {DraggableRow} from "../../components/DraggableRow";
import {toSafeDateString} from "../../utils/dates";
import AddEditTodo from "../../components/AddTodo";
import {Dropdown, Menu, Button} from 'antd';
import {
    SaveOutlined,
    EditOutlined,
    CopyOutlined,
    DeleteOutlined,
    FileAddOutlined,
    CloseOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import {DownOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from "react-router-dom";
import {AddProject} from '../../components/AddProject';
import {ProjectsFilter} from '../../components/ProjectsFilter';
import {ClientSelectionModal} from '../../components/ClientSelectionModal';


export const ToDoPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [selectedMemberIds, setSelectedMemberIds] = useState<number[]>([]);
    const [selectedStatusId, setSelectedStatusId] = useState<number[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditProjectModalVisible, setIsEditProjectModalVisible] = useState(false);
    const [toDoList, setTodoList] = useState<ToDoResponse>({count: 0, data: []});
    const [error, setError] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false);
    const [initialData, setInitialData] = useState<any>(null);
    const [project, setProject] = useState<Project | null>(null);
    const [sorting, setSorting] = useState({field: null, order: null});
    const [showCopyModal, setShowCopyModal] = useState(false);
    const initialPaginationState = {
        current: 1,
        pageSize: 10,
    }
    const [pagination, setPagination] = useState<any>(initialPaginationState);
    const items = [
        {label: 'List', key: 'list'},
    ];

    useEffect(() => {
        getCurrentProject();
    }, []);

    const getCurrentProject = () => {
        return api.get<Project>(`/project/${id}`)
            .then(({data}) => {
                setProject(data);
            })
            .catch((err) => {
                message.error(errorMsg(err), 5)
                setLoading(false);
            })
    }

    useEffect(() => {
        getTodoList();
    }, [sorting, selectedMemberIds.length, selectedStatusId, pagination.pageSize, pagination.current])

    const handleMemberChange = (memberIds: number[]) => {
        setSelectedMemberIds(memberIds);
        setPagination(initialPaginationState)
    };

    const handleStatusChange = (statusId: number[]) => {
        setSelectedStatusId(statusId);
        setPagination(initialPaginationState)
    };

    const getTodoList = () => {
        setLoading(true);
        const params: any = {};
        if (sorting.field && sorting.order) {
            params.orderBy = sorting.field;
            params.orderDir = sorting.order === 'ascend' ? 'ASC' : 'DESC';
        }
        if (selectedMemberIds.length) {
            params.user = selectedMemberIds.join(',');
        }
        if (selectedStatusId.length) {
            params.status = selectedStatusId.join(',')
        }

        params.project = id;
        params.limit = pagination.pageSize;
        params.offset = (pagination.current - 1) * pagination.pageSize

        return api.get<ToDoResponse>('/to-do', {params: params})
            .then(({data}) => {
                setTodoList(data)
                setPagination({
                    ...pagination,
                    total: data.count,
                });
                setError(false)
                setLoading(false);
            })
            .catch((err) => {
                message.error(errorMsg(err), 5)
                setLoading(false);
            })
    }

    const handleOk = () => {
        getTodoList();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleEditProjectCancel = () => {
        setIsEditProjectModalVisible(false);
    };

    const handleEditProject = () => {
        getCurrentProject();
        setIsEditProjectModalVisible(false);
    };

    const openAddModal = () => {
        setIsEditMode(false);
        setInitialData({
            status: getToDoStatusString(TODO_STATUSES_ENUM.New)
        });
        setIsModalVisible(true);
    };

    const openEditModal = (data: any) => {
        setIsEditMode(true);
        setInitialData(data);
        setIsModalVisible(true);
    };

    const deleteProject = async () => {
        await api.delete(`/project/${id}`);
        navigate(`/projects`);
    }

    const snooze = async () => {
        const body = {
            status: 3
        }
        await api.patch(`/project/${id}`, body)
        navigate(`/projects`);
    }

    const close = async () => {
        const body = {
            status: 2
        }
        await api.patch(`/project/${id}`, body)
        navigate(`/projects`);
    }

    const copy = async () => {
        setShowCopyModal(true);
    }

    const saveAsTemplate = async () => {
        const body = {
            project: id ? +id : undefined
        }
        await api.post(`/project/template`, body);
        navigate(`/templates`);
    }

    const createProject = async () => {
        setShowCopyModal(true);
    }

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setSorting({
            field: sorter.field,
            order: sorter.order,
        });
    };

    const handleModalOk = async (selectedKeys: string[]) => {
        if (project?.type === 2) {
            const body = {
                application: selectedKeys?.[0],
                project: Number(id)
            }

            await api.post(`project/copy`, body)
            setShowCopyModal(false);
        } else {
            const body = {
                application: selectedKeys?.[0],
            }
            await api.post(`project/${id}`, body);
            navigate(`/projects`)

        }

    };

    const menu = project?.type === 2 ? (
        <Menu>
            <Menu.Item key="1" onClick={saveAsTemplate} icon={<SaveOutlined/>}>
                Save As Template
            </Menu.Item>
            <Menu.Item key="2" onClick={() => setIsEditProjectModalVisible(true)} icon={<EditOutlined/>}>
                Edit
            </Menu.Item>
            <Menu.Item key="3" icon={<ClockCircleOutlined/>} onClick={snooze}>
                Snooze
            </Menu.Item>
            <Menu.Item key="4" icon={<CloseOutlined/>} onClick={close}>
                Close
            </Menu.Item>
            <Menu.Item key="5" icon={<CopyOutlined/>} onClick={copy}>
                Copy for other client
            </Menu.Item>
            <Menu.Item key="6" onClick={deleteProject} icon={<DeleteOutlined/>}>
                Delete Project
            </Menu.Item>
        </Menu>
    ) : (
        <Menu>
            <Menu.Item key="1" onClick={createProject} icon={<FileAddOutlined/>}>
                Create project from template
            </Menu.Item>
            <Menu.Item key="2" onClick={() => setIsEditProjectModalVisible(true)} icon={<EditOutlined/>}>
                Edit
            </Menu.Item>
            <Menu.Item key="3" onClick={deleteProject} icon={<DeleteOutlined/>}>
                Delete Project
            </Menu.Item>
        </Menu>
    );


    if (error) return <ErrorMessagePage title='To Do'/>

    return (
        <Page
            title={`${project?.name ? project?.name : ''} ${project?.application ? '-' : ''}  ${project?.application ? (project?.application?.alias || project?.application?.name) : ''}`}>
            <HeadTitle title={`${project?.name ? project?.name : ''} ${project?.application ? '-' : ''}  ${project?.application ? (project?.application?.alias || project?.application?.name) : ''}`}/>
            <FlexSpace style={{justifyContent: 'space-between'}}>
                <div>
                    {project?.startDate && <Typography.Text>
                        Starts on {toSafeDateString(project?.startDate, 'MMM Do, YYYY')}
                    </Typography.Text>}
                    {project?.startDate && <Divider type="vertical"/>}
                    {project?.dueDate && <Typography.Text>
                        Due on {toSafeDateString(project?.dueDate, 'MMM Do, YYYY')}
                    </Typography.Text>}
                    {project?.dueDate && <Divider type="vertical"/>}
                    {project?.recurring && <Typography.Text>
                        {PROJECT_RECURRING_ENUM[project.recurring]}
                    </Typography.Text>}
                    {project?.accounting_period && <Divider type="vertical"/>}
                    {project?.accounting_period && <Typography.Text>
                        {PROJECT_ACCOUNTING_PERIOD_ENUM[project.accounting_period]}
                    </Typography.Text>}
                </div>
                <FlexSpace>
                <Dropdown overlay={menu} placement="bottomRight">
                    <Button>
                        Actions <DownOutlined/>
                    </Button>
                </Dropdown>
                <Btn type="primary" title="Back" onClick={() => navigate(-1)}/>
                </FlexSpace>
            </FlexSpace>
            <Tabs
                items={items}
                style={{marginBottom: 16}}
                className="NoSelect"
            />
            <FlexSpace direction="vertical" size="large">
                <FlexSpace size="large">
                    <ProjectsFilter project={project} onMemberChange={handleMemberChange}
                                    onStatusChange={handleStatusChange}/>
                    <Btn type="primary" title="New" onClick={openAddModal} style={{marginTop: 8}}/>
                </FlexSpace>
                <Block className="FullTable">
                    <Spin spinning={loading}>
                        <Table
                            components={{
                                body: {
                                    row: DraggableRow,
                                },
                            }}
                            pagination={pagination}
                            rowKey="id"
                            columns={columns}
                            dataSource={toDoList.data}
                            onRow={(record) => {
                                return {
                                    onClick: (event) => {
                                        openEditModal(record)
                                    },
                                };
                            }}
                            onChange={handleTableChange}
                        />
                    </Spin>
                </Block>

            </FlexSpace>
            <AddEditTodo
                isVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
                title={isEditMode ? '' : 'Add Task'}
                isEditMode={isEditMode}
                initialData={initialData}
                project={project}
            />
            <AddProject
                isVisible={isEditProjectModalVisible}
                handleOk={handleEditProject}
                handleCancel={handleEditProjectCancel}
                title={''}
                isEditMode
                initialData={project}
            />
            <ClientSelectionModal
                visible={showCopyModal}
                onOk={handleModalOk}
                onCancel={() => setShowCopyModal(false)}
            />
        </Page>
    )
}

const columns: any = [
    {
        key: 'sort',
    },
    {
        title: 'Task Name',
        dataIndex: 'title',
        field: 'title',
        sorter: true
    },
    {
        title: 'Assignee',
        dataIndex: 'user',
        field: 'user',
        sorter: true,
        render: (user: any) => {
            return <p>
                {user?.firstName} {user?.lastName}
            </p>
        }
    },
    {
        title: 'Due Date',
        sorter: true,
        field: 'dueDate',
        dataIndex: 'dueDate',
        render: (dueDate: string) => {
            return <FlexSpace>
                {dueDate ? toSafeDateString(dueDate) : ''}
            </FlexSpace>
        }
    },
    {
        title: 'Status',
        sorter: true,
        dataIndex: 'status',
        field: 'status',
        render: (status: number) => {
            return <FlexSpace>
                {getToDoStatusString(status)}
            </FlexSpace>
        }
    }
]
