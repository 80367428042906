import React, {useEffect, useState} from 'react'
import {message, Progress, Spin, Table, TablePaginationConfig} from 'antd'
import {Btn} from 'components/Btn'
import {FlexSpace} from 'components/FlexSpace'
import {HeadTitle} from 'components/HeadTitle'
import {Page} from 'components/Page'
import {Block} from 'components/Block'

import {
    getProjectRecurringStatusString, getProjectStatusString,
    Project,
    PROJECT_RECURRING_ENUM, PROJECT_STATUSES_ENUM,
} from 'types/todo.types'
import {api} from "../../utils/axios";
import {errorMsg} from 'utils/errorMsg'

import {ErrorMessagePage} from "../errorMessage/ErrorMessage.page";
import {toSafeDateString} from "../../utils/dates";
import {AddProject} from "../../components/AddProject";
import {useNavigate} from "react-router-dom";
import {ProjectFilters} from "../../components/ProjectFilters";

export const ProjectsPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [projectList, setProjectList] = useState<Project[]>([]);
    const [error, setError] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false);
    const [initialData, setInitialData] = useState<any>(null);
    const [selectedCompanyIds, setSelectedCompaniesId] = useState<string[]>([]);
    const [selectedMemberIds, setSelectedMembersIds] = useState<string[]>([]);
    const [selectedStatusIds, setSelectedStatusesId] = useState<string[]>([]);
    const [sorting, setSorting] = useState({field: null, order: null});
    const initialPaginationState = {
        current: 1,
        pageSize: 10,
    }
    const [pagination, setPagination] = useState<any>(initialPaginationState);

    useEffect(() => {
        getProjectList();
    }, [sorting, selectedStatusIds?.length, selectedCompanyIds?.length, selectedMemberIds?.length, pagination.pageSize, pagination.current])

    const getProjectList = () => {
        setLoading(true);

        const params: any = {};
        if (sorting.field && sorting.order) {
            params.orderBy = sorting.field;
            params.orderDir = sorting.order === 'ascend' ? 'ASC' : 'DESC';
        }
        if (selectedCompanyIds?.length) {
            params.application = selectedCompanyIds.join(',');
        }
        if (selectedMemberIds.length) {
            params.user = selectedMemberIds.join(',');
        }
        if (selectedStatusIds.length) {
            params.status = selectedStatusIds.join(',')
        }

        params.limit = pagination.pageSize;
        params.offset = (pagination?.current - 1) * pagination?.pageSize


        return api.get<{ count: number, data: Project[] }>('/project', {params: params})
            .then(({data}) => {
                setProjectList(data.data)
                setError(false)
                setLoading(false);
                setPagination({
                    ...pagination,
                    total: data.count,
                });
            })
            .catch((err) => {
                message.error(errorMsg(err), 5)
                setLoading(false);
            })
    }

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPagination(pagination)
        setSorting({
            field: sorter.field || sorter.columnKey,
            order: sorter.order,
        });
    };

    const handleOk = () => {
        getProjectList();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const openAddModal = () => {
        setIsEditMode(false);
        setInitialData({
            recurring: getProjectRecurringStatusString(PROJECT_RECURRING_ENUM['Yes']),
            status: getProjectStatusString(PROJECT_STATUSES_ENUM.New)
        });

        setIsModalVisible(true);
    };

    const handleCompanyChange = (companyIds: string[]) => {
        setSelectedCompaniesId(companyIds);
        setPagination(initialPaginationState)
    };

    const handleMemberChange = (memberIds: string[]) => {
        const members = memberIds.filter((item) => item !==  'All');
        setSelectedMembersIds(members);
        setPagination(initialPaginationState)
    };

    const handleStatusChange = (statusIds: string[]) => {
        setSelectedStatusesId(statusIds);
        setPagination(initialPaginationState)
    };

    if (error) return <ErrorMessagePage title='To Do'/>

    return (
        <Page title={'Projects'}>
            <HeadTitle title="Projects"/>
            <FlexSpace direction="vertical" size="large">
                <FlexSpace size="large" >
                    <ProjectFilters onCompanyChange={handleCompanyChange} onMemberChange={handleMemberChange} selectedMemberIds={selectedMemberIds}
                                    onStatusChange={handleStatusChange} />
                    <Btn type="primary" title="New" onClick={openAddModal} style={{marginTop: 8}}/>
                </FlexSpace>
                <Block className="FullTable">
                    <Spin spinning={loading}>

                        <Table
                            className="RecTable Table"
                            dataSource={projectList}
                            columns={columns}
                            pagination={pagination}
                            rowKey={'id'}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        navigate(`/projects/${record.id}`)
                                    },
                                };
                            }}
                            onChange={handleTableChange}
                        />
                    </Spin>
                </Block>

            </FlexSpace>
            <AddProject
                isVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
                title={isEditMode ? '' : 'Add Project'}
                isEditMode={isEditMode}
                initialData={initialData}
            />
        </Page>
    )
}

const columns: any = [
    {
        title: 'Project',
        field: 'name',
        key: 'name',
        sorter: true,
        render: (row: Project) => {
            const {name, allToDos, completedToDos} = row || {}
            const percent = allToDos > 0 ? (completedToDos / allToDos) * 100 : 0;

            return <FlexSpace style={{flexDirection: 'column', alignItems: 'start'}}>
                <span>{name}</span>
                <Progress
                    style={{width: 300}}
                    percent={percent}
                    format={() => `${completedToDos}/${allToDos}`}
                />
            </FlexSpace>

        }
    },
    {
        title: 'Client',
        field: 'application',
        key: 'application',
        sorter: true,
        render: (row: Project) => {
            const {alias, name} = row.application || {}
            return <FlexSpace>
                {alias || name || '-'}
            </FlexSpace>

        }
    },

    {
        title: 'Start Date',
        field: 'startDate',
        dataIndex: 'startDate',
        sorter: true,
        render: (startDate: string) => {
            return <FlexSpace>
                {startDate ? toSafeDateString(startDate) : '-'}
            </FlexSpace>
        }
    },
    {
        title: 'Due Date',
        field: 'dueDate',
        sorter: true,
        dataIndex: 'dueDate',
        render: (dueDate: string) => {
            return <FlexSpace>
                {dueDate ? toSafeDateString(dueDate) : ''}
            </FlexSpace>
        }
    },
    {
        title: 'Assignee',
        dataIndex: 'user',
        key: 'user',
        field: 'user',
        sorter: true,
        render: (user: any) => {
            return <p>
                {user?.firstName} {user?.lastName}
            </p>
        }
    },
    {
        title: 'Status',
        key: 'status',
        field: 'status',
        dataIndex: 'status',
        sorter: true,
        render: (status: any) => {
            return <FlexSpace>
                {status ? getProjectStatusString(status) : ''}
            </FlexSpace>
        }
    },
]
