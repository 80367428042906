import { api } from 'utils/axios'

import { IAccount, ICompany, ICompanyEntity, ICompanySettings } from 'types/company.types'

export function createCompany() {
  const company = { name: 'Temp', qbo_id: '0' }
  return api.post<ICompany>(`/applications`, company)
    .then(res => res.data)
}

export function getCompany(companyId: number) {
  return api.get<ICompany>(`/applications/${companyId}`)
    .then(res => res.data)
}

export function getCompanies() {
  return api.get<ICompany[]>('/applications')
    .then(res => res.data)
}

export function updateCompany(company: ICompany) {
  const { name, entity, qbo_id } = company
  return api.post(`/applications/${company.id}`, { name, entity, qbo_id })
    .then(res => res.data)
}

export function saveSettings(companyId: number, settings: ICompanySettings) {
  return api.post(`/applications/${companyId}/settings`, { settings })
    .then(res => res.data)
}

export function createIntergation(companyId: number, token: string, provider: 'Rutter' | 'Finch') {
  return api.post('/integrations', { token, companyId, provider })
    .then(res => res.data)
}

export function revokeQboIntegration(companyId: number) {
  const payload = { data: { application: companyId } }
  return api.delete('/integrations/qbo', payload)
    .then(res => res.data)
}

export function deleteIntegration(companyId: number, id: number) {
  const params = { companyId, id }
  return api.delete('/integrations/', { params })
    .then(res => res.data)
}

export function getEntities(): Promise<ICompanyEntity[]> {
  return api.get<ICompanyEntity[]>('/applications/entities')
    .then(res => res.data)
    .then(data => [...data, { id: 0, title: 'I don’t know my entity type' }])
}

export function getAccounts(companyId: number) {
  return api.get<IAccount[]>(`/integrations/qbo/accounts?application=${companyId}`)
    .then(res => res.data)
}

export async function addQboCompany() {
  const { data: url } = await api('/integrations/qbo')
  const popup = window.open(url, 'QuickBooks','width=760,height=640')

  let i = 0
  localStorage.setItem('eq_message', '')

  return new Promise((resolve, reject) => {
    const interval = setInterval(function() {
      i++
      const message = localStorage.getItem('eq_message')

      // console.log('addQboCompany', message)

      if (message) {
        const data = JSON.parse(message || '{}')

        if (data?.type === 'COMPANY_ADDED')
          resolve(data)
      
        if (data?.type === 'COMPANY_ADD_DENIED') 
          reject(`Error: ${data?.msg || 'Access denied.'}`)
      }

      if (popup?.closed || i > 300) {
        clearInterval(interval)
        reject('Error: Access denied.')
      }
    }, 500)
  })
}

export function fetchPlaidAccounts(companyId: number) {
  return api.get(`/plaid/accounts?applicationId=${companyId}`)
    .then(res => res.data)
}

export function savePlaidToken(publicToken: string, companyId: number) {
  const body = { publicToken, applicationId: companyId }
  return api.post(`/plaid/exchange`, body)
    .then(res => res.data)
}

export function deletePlaidItem(companyId: number, itemId: string) {
  const params = { applicationId: companyId, itemId }
  return api.delete('/plaid/accounts', { params })
    .then(res => res.data)
}

export type ISetupData = Partial<ICompany>