import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { DatePicker, Select, Spin, Typography, message } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

import { Block } from 'components/Block'
import { NestedTable } from 'components/NestedTable'
import { FlexSpace } from 'components/FlexSpace'
import { Header } from 'components/Header'

import { api } from 'utils/axios'
import { currentCompany } from 'recoil/companies.state'
import { AccountTypes, SerializedColumnBy } from 'types/chart.types'
import { toSafeDateString } from 'utils/dates'
import { DateRange } from 'types/financial.type'
import { errorMsg } from 'utils/errorMsg'

const { Text } = Typography

dayjs.extend(quarterOfYear)

export const Financials = () => {
  const company = useRecoilValue(currentCompany)

  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState<Dayjs | null>(dayjs().startOf('month').add(-1, 'months'))

  const [expandLevel, setExpandLevel] = useState(1)
  const [accountingMethod, setAccountingMethod] = useState<string>('Cash')
  const [accountType, setAccountType] = useState<keyof typeof AccountTypes>('PL')

  const [serializedColumnBy, setSerializedColumnBy] = useState<SerializedColumnBy>('month')
  const [report, setReport] = useState<any[]>([])
  const [dateRange, setDateRange] = useState<DateRange | null>(null)
  const [startDate, setStartDate] = useState<string>()
  const [comparedDate, setComparedDate] = useState<Dayjs | null>(date ? date.clone().subtract(1, 'month') : null)

  const accountingMethods = [
    { value: 'Cash', label: 'Cash-Basis' },
    { value: 'Accrual', label: 'Accrual-Basis' },
  ]

  const accountTypes = [
    { label: 'Profit & Loss Summary', value: 'PL' },
    { label: 'Balance Sheet Summary', value: 'BS' },
    { label: 'Cash Flow Summary', value: 'CF' },
  ]

  const showingLevels = [
    { label: 'Summary', value: 1 },
    { label: 'Level 1', value: 2 },
    { label: 'Level 2', value: 3 },
    { label: 'Level 3', value: 4 },
  ]

  const serializedColumnByTypes = [
    { label: 'Month', value: 'month', type: 'month' },
    { label: 'Quarter', value: 'quarter', type: 'quarter' },
    { label: 'Year', value: 'year', type: 'year' },
  ]

  const onSerializedColumnByTypeChange = (e: SerializedColumnBy) => {
    setSerializedColumnBy(e)
    setComparedDate(dayjs(startDate).subtract(1, e))
  }

  const onAccountMethodChange = (e: string) => {
    setAccountingMethod(e)
  }

  const handleDateChange = (value: Dayjs | null) => {
    setDate(value)
    setComparedDate(dayjs(value).subtract(1, serializedColumnBy))
  }

  const disabledDate = (current: Dayjs) => {
    const currentPeriod = dayjs().add(-1, serializedColumnBy)
    return (current && current < dayjs(startDate)) || (current && current.isAfter(currentPeriod))
  }

  const getDefaultValue = () => {
    switch (serializedColumnBy) {
      case 'month':
        return dayjs().startOf('month').add(-1, 'months')
      case 'quarter':
        return dayjs().startOf('quarter').add(-1, 'quarters')
      case 'year':
        return dayjs().startOf('year').add(-1, 'years')
      default:
        return dayjs()
    }
  }

  useEffect(() => {
    getData()
  }, [date, serializedColumnBy, accountType, accountingMethod, comparedDate])

  useEffect(() => {
    getAccountInfo()
  }, [])

  const onAccountTypeChange = (e: keyof typeof AccountTypes) => {
    setReport([])
    setDateRange(null)
    setAccountType(e)
  }

  const getAccountInfo = () => {
    const params = {
      applicationId: company?.id,
    }
    api.get<any>('/finreview/qbo/account_info', { params }).then((res: any) => {
      if (res.data.CompanyStartDate) {
        setStartDate(res.data.CompanyStartDate)
      }
    })
  }

  const calculateDates = (period: string, currentDate: any) => {
    let start_date, end_date
    if (period === 'month') {
      start_date = currentDate.clone().startOf('month')
      end_date = currentDate.clone().endOf('month')
    } else if (period === 'quarter') {
      start_date = currentDate.clone().startOf('quarter')
      end_date = currentDate.clone().endOf('quarter')
    } else if (period === 'year') {
      start_date = currentDate.clone().startOf('year')
      end_date = currentDate.clone().endOf('year')
    }
    return { start_date, end_date }
  }

  const disabledComparedDate = (current: Dayjs) => {
    if (!date) return false

    let periodStart
    switch (serializedColumnBy) {
      case 'month':
        periodStart = date.clone().add(-1, 'months')
        break
      case 'quarter':
        periodStart = date.clone().add(-1, 'quarter')
        break
      case 'year':
        periodStart = date.clone().add(-1, 'year')
        break
      default:
        return false
    }

    return current.isAfter(periodStart, 'day')
  }

  const getData = () => {
    setLoading(true)
    const { start_date, end_date } = calculateDates(serializedColumnBy, date)
    const params = {
      applicationId: company?.id,
      accounting_method: accountingMethod,
      start_date: toSafeDateString(start_date),
      comparedStartDate: comparedDate ? toSafeDateString(comparedDate) : end_date,
      type: accountType,
      period: serializedColumnBy,
    }
    api
      .get('/finreview/tree', { params })
      .then((res) => {
        if (res.data.dateRange) setDateRange(res.data.dateRange)
        if (res.data.table) setReport(res.data.table)
      })
      .catch((err) => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
  }

  console.log(toSafeDateString(date as any), toSafeDateString(comparedDate as any))

  return (
    <Block style={{ minHeight: 500 }}>
      <FlexSpace direction="vertical" size="large">
        <Header size={28}>Financial Statements</Header>
        <FlexSpace>
          <Text>View</Text>
          <Select
            defaultValue={accountType}
            options={accountTypes}
            onChange={onAccountTypeChange}
            style={{ width: 220 }}
          />
          <Text>report basis</Text>
          <Select
            style={{ width: 150 }}
            value={accountingMethod}
            options={accountingMethods}
            onChange={onAccountMethodChange}
          />
          <Text>showing</Text>
          <Select style={{ width: 220 }} defaultValue={1} options={showingLevels} onChange={setExpandLevel} />
        </FlexSpace>
        <FlexSpace>
          <Text>For the</Text>
          <Select
            style={{ width: 100 }}
            value={serializedColumnBy}
            options={serializedColumnByTypes}
            onChange={onSerializedColumnByTypeChange}
          />
          <Text>of</Text>
          <DatePicker
            picker={serializedColumnBy}
            defaultValue={getDefaultValue()}
            value={date}
            onChange={handleDateChange}
            disabledDate={disabledDate}
          />
          <Text>compared with</Text>
          <DatePicker
            picker={serializedColumnBy}
            value={comparedDate}
            onChange={setComparedDate}
            disabledDate={disabledComparedDate}
          />
        </FlexSpace>
        <Spin spinning={loading}>
          {!!report.length && (
            <NestedTable
              data={report}
              accountType={accountType}
              expandLevel={+expandLevel}
              period={serializedColumnBy}
              accountingMethod={accountingMethod}
              startDate={date}
              serializedColumnBy={serializedColumnBy}
              dateRange={dateRange}
            />
          )}
        </Spin>
      </FlexSpace>
    </Block>
  )
}
