import { Divider, Form, Input, message, Spin, Typography } from 'antd'

import { Btn } from 'components/Btn'

import { rules } from 'pages/auth/auth.utils'
import { useState } from 'react'

import { api } from 'utils/axios'
import { errorMsg } from 'utils/errorMsg'

export const ChangePassword = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleSubmit = (data: any) => {
    const { pass, newpass, confirmpass } = data

    if (newpass !== confirmpass) {
      message.error('New Password not equal to Comfirm Password')
    }

    setLoading(true)

    api
      .post('/auth/update-password', { pass, newpass })
      .then(() => {
        message.success('Password successfully changed!')
        form.resetFields()
      })
      .catch((err) => message.error(errorMsg(err), 5))
      .finally(() => setLoading(false))
  }

  const confirmpassRule = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue('newpass') === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('The two passwords that you entered do not match!'))
    }
  })

  const formProps = {
    form,
    layout: 'vertical' as any,
    size: 'large' as any,
    style: { width: 300 },
    requiredMark: false,
    scrollToFirstError: true
  }

  return (
    <Spin spinning={loading}>
      <Typography.Title level={4}>Change Password</Typography.Title>
      <Form {...formProps} onFinish={handleSubmit}>
        <Form.Item name="pass" label="Old Password" rules={rules.password}>
          <Input.Password placeholder="password" autoComplete="new-password" />
        </Form.Item>
        <Form.Item name="newpass" label="New Password" rules={rules.password}>
          <Input.Password placeholder="password" autoComplete="new-password" />
        </Form.Item>
        <Form.Item name="confirmpass" label="Confirm Password" rules={[...rules.password, confirmpassRule]}>
          <Input.Password placeholder="password" autoComplete="new-password" />
        </Form.Item>
        <Form.Item>
          <Btn title="Submit" type="primary" htmlType="submit" block loading={loading} />
        </Form.Item>
      </Form>
      <Divider />
    </Spin>
  )
}
