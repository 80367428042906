import { useRecoilValue } from 'recoil'
import { Divider, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { Btn } from 'components/Btn'

import { currentUser } from 'recoil/user.state'
import { IUser } from 'types/user.types'

interface StepsProps {
  onSubmit: () => void
}

const { Text, Title } = Typography

export const WelcomeStep = (props: StepsProps) => {
  const { onSubmit } = props

  const user = useRecoilValue(currentUser)

  return (
    <FlexSpace direction="vertical" size="large">
      <Title level={5}>Hey {getUserName(user)}, thanks for choosing Equility!​</Title>
      <Text strong>How does Equility work?​</Text>
      <Text strong>We integrate the data from your accounting platform and digital apps, providing you with a real-time audit of your accounting accuracy. Our platform also provides automation assistance for manual accounting tasks, and access to real-time financial analytics. Cool, huh?!​​</Text>
      <Text strong>In case you were wondering…​​</Text>
      <Text strong>Our data integration process is 100% secure, so no worries on that end!​​</Text>
      <Text strong>We cannot and will not make any changes to your apps, and you can revoke our access to your accounts at any time.​​</Text>
      <Text strong>In you need anything else, please contact us at support@equilityhq.com. We’re here to assist in whatever way necessary!​​</Text>
      <Divider />
      <Btn type="primary" title="Start" onClick={onSubmit} />
    </FlexSpace>
  )
}

function getUserName(user: IUser | null) {
  if (!user) return 'User'
  const username = (user.firstName || '') + (user.firstName ? ' ' : '') + (user.lastName || '') || user.email
  return username
}
