import { useState } from 'react'
import { Checkbox, Input, Segmented, Space, Table, Typography } from 'antd'

import { FlexSpace } from 'components/FlexSpace'
import { IAccount } from 'types/company.types'

const { Text } = Typography

interface Props {
  value?: string[]
  accounts: IAccount[]
  loading?: boolean
  onChange: (value: string[]) => void
}

export const SelectAccounts = (props: Props) => {
  const { accounts, loading, value = [], onChange } = props

  const [filter, setFilter] = useState('')
  const [all, setAll] = useState(true)

  const columns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      width: 200,
      sorter: (a: any, b: any) => a.number?.localeCompare(b.number),
    },
    {
      title: 'Account Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
    },
    {
      title: '',
      dataIndex: 'qboId',
      width: 50,
      render: (qboId: string) => (
        <Checkbox checked={value.indexOf(qboId) > -1} onChange={() => handleSelect(qboId)} />
      ),
    },
  ]

  const handleSelect = (id: string) => {
    const arr = [...value]
    const isSelected = value.indexOf(id) > -1

    if (!isSelected) {
      onChange([...arr, id])
    } else {
      arr.splice(value.indexOf(id), 1)
      onChange([...arr])
    }
  }

  const dataSource = accounts
    .filter(v => v.active)
    .filter(v => {
      if (!filter) return true
      if (!all) return true
      const f = filter.toLowerCase()
      return v.name?.toLowerCase().includes(f) || v.number?.includes(f)
    })
    .filter(v => all ? true : value.includes(v.qboId))

  return (
    <FlexSpace direction="vertical">
      <FlexSpace direction="horizontal" spacebetween>
        <Input
          type="text"
          placeholder="Quick Search"
          value={filter}
          onChange={(v) => setFilter(v.target.value || '')}
          allowClear
          style={{ width: 400 }}
          disabled={!all}
        />
        <Space>
          <Text>Show:</Text>
          <Segmented
            options={['All', 'Selected']}
            value={all ? 'All' : 'Selected'}
            onChange={(v) => setAll(v === 'All')}
          />
        </Space>
      </FlexSpace>

      <Table 
        rowKey={(record: IAccount) => record.qboId}
        dataSource={dataSource} 
        columns={columns} 
        scroll={{ y: 540 }} 
        pagination={false} 
        loading={loading}
      />
    </FlexSpace>
  )
}
