import {useSortable} from "@dnd-kit/sortable";
import React, {Children, cloneElement, CSSProperties, HTMLAttributes, ReactElement} from "react";
import {CSS} from "@dnd-kit/utilities";
import { MenuOutlined } from '@ant-design/icons';

interface RowProps extends HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

export const DraggableRow = ({ children, ...props }: RowProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    });

    const style: CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9 } : {}),
    };

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {Children.map(children, (child) => {
                if ((child as ReactElement).key === 'sort') {
                    return cloneElement(child as React.ReactElement, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{ touchAction: 'none', cursor: 'move' }}
                                {...listeners}
                            />
                        ),
                    });
                }
                return child;
            })}
        </tr>
    );
};
