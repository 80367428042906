import React, {useEffect, useState} from 'react'
import {message, Spin, Table, TablePaginationConfig} from 'antd'
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import axios from "axios";
import {FlexSpace} from 'components/FlexSpace'
import {HeadTitle} from 'components/HeadTitle'
import {Page} from 'components/Page'
import {Block} from 'components/Block'

import {getToDoStatusString, ITodo, Project, TODO_STATUSES_ENUM, ToDoResponse} from 'types/todo.types'
import {api} from "../../utils/axios";
import {errorMsg} from 'utils/errorMsg'

import {ErrorMessagePage} from "../errorMessage/ErrorMessage.page";
import {DraggableRow} from "../../components/DraggableRow";
import {toSafeDateString} from "../../utils/dates";
import {ToDoFilter} from "../../components/ToDoFilter";
import AddEditTodo from "../../components/AddTodo";
import {Btn} from "../../components/Btn";


export const TasksPage = () => {
    const [loading, setLoading] = useState(false)
    const [projectList, setProjectList] = useState<Project[]>([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string[]>([]);
    const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
    const [selectedStatusId, setSelectedStatusId] = useState<string[]>([]);
    const [toDoList, setTodoList] = useState<ToDoResponse>({count: 0, data: []});
    const [error, setError] = useState(false)
    const [sorting, setSorting] = useState({field: null, order: null});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [initialData, setInitialData] = useState<any>(null);
    const [search, setSearch] = useState('')
    const [isEditMode, setIsEditMode] = useState(false);
    const [allTasks, setAllTasks] = useState(false);
    const initialPaginationState = {
        current: 1,
        pageSize: 10,
    }
    const [pagination, setPagination] = useState<any>(initialPaginationState);


    useEffect(() => {
        const abortFn = getTodoList();
        return () => {
            abortFn();
        };
    }, [sorting, selectedCompanyId?.length, selectedMemberIds?.length, selectedStatusId?.length, search, pagination.pageSize, pagination.current])


    useEffect(() => {
        getProjectList()
    }, []);

    const getProjectList = () => {
        const params: any = {
            limit: 10000,
            offset: 0
        };

        return api.get<{ count: number, data: Project[] }>('/project', {params: params})
            .then(({data}) => {
                setProjectList(data.data)
            })
            .catch((err) => {
                message.error(errorMsg(err), 5)
            })
    }
    const handleCompanyChange = (companyId: string[]) => {
        setSelectedCompanyId(companyId);
        setPagination(initialPaginationState)
    };

    const handleMemberChange = (memberIds: string[]) => {
        setSelectedMemberIds(memberIds);
        setPagination(initialPaginationState)
    };

    const handleStatusChange = (statusId: string[]) => {
        setSelectedStatusId(statusId);
        setPagination(initialPaginationState)
    };

    const handleSearchChange = (query: string) => {
        setSearch(query);
        setPagination(initialPaginationState)
    }

    const getTodoList = () => {
        setLoading(true);
        const controller = new AbortController();
        const { signal } = controller;

        const params: any = {};
        if (sorting.field && sorting.order) {
            params.orderBy = sorting.field;
            params.orderDir = sorting.order === 'ascend' ? 'ASC' : 'DESC';
        }
        if (selectedCompanyId.length) {
            params.application = selectedCompanyId.join(',');
        }
        if (selectedMemberIds.length) {
            params.user = selectedMemberIds.join(',');
        }
        if (selectedStatusId.length) {
            params.status = selectedStatusId.join(',')
        }
        if (search) {
            params.title = search
        }
        params.limit = pagination.pageSize;
        params.offset = (pagination?.current - 1) * pagination?.pageSize

        api.get('/to-do', { params, signal })
            .then(({ data }) => {
                setTodoList(data);
                setPagination((prev: any) => ({ ...prev, total: data.count }));
                setError(false);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    message.error('Error fetching data');
                }
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            controller.abort();
        };

    }

    const openEditModal = (data: any) => {
        setIsEditMode(true);
        setAllTasks(false);
        setInitialData(data);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setInitialData(null);
    };

    const handleOk = () => {
        getTodoList();
        setIsModalVisible(false);
    };

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPagination(pagination)
        setSorting({
            field: sorter.field || sorter.columnKey,
            order: sorter.order,
        });
    };

    const openAddModal = () => {
        setIsEditMode(false);
        setAllTasks(true);
        setInitialData({
            status: getToDoStatusString(TODO_STATUSES_ENUM.New)
        });
        setIsModalVisible(true);
    };

    if (error) return <ErrorMessagePage title='To Do'/>

    return (
        <Page title={'Tasks'}>
            <HeadTitle title="Tasks"/>
            <FlexSpace direction="vertical" size="large">
                <FlexSpace direction="vertical" size="large" style={{alignItems: 'flex-start', flexDirection: 'row'}}>
                    <FlexSpace  style={{flexWrap: 'wrap'}}>
                        <ToDoFilter onCompanyChange={handleCompanyChange} search={search} setSearch={handleSearchChange} onMemberChange={handleMemberChange}
                                    onStatusChange={handleStatusChange} />
                    </FlexSpace>
                    <FlexSpace  style={{flexWrap: 'wrap'}}>
                        <Btn type="primary" title="New" onClick={openAddModal} style={{marginTop: 32}} />
                    </FlexSpace>
                    </FlexSpace>
                <Block className="FullTable">
                    <Spin spinning={loading}>
                        <SortableContext
                            items={toDoList?.data.map((i) => i.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            <Table
                                components={{
                                    body: {
                                        row: DraggableRow,
                                    },
                                }}
                                pagination={pagination}
                                rowKey="id"
                                columns={columns}
                                dataSource={toDoList.data}
                                onChange={handleTableChange}
                                onRow={(record) => {
                                    return {
                                        onClick: (event) => {
                                            openEditModal(record)
                                        },
                                    };
                                }}
                            />
                        </SortableContext>
                    </Spin>
                </Block>

            </FlexSpace>
            <AddEditTodo
                isVisible={isModalVisible}
                allTasks={allTasks}
                projectList={projectList}
                handleOk={handleOk}
                handleCancel={handleCancel}
                title={isEditMode ? '' : 'Add To Do'}
                isEditMode={isEditMode}
                initialData={initialData}
                project={initialData?.project}
            />
        </Page>
    )
}

const columns: any = [
    {
        title: 'Project Name',
        sorter: true,
        key: 'project',
        field: 'project',
        render: (row: any) => {
            const {name} = row.project || {}
            return <FlexSpace>
                {name}
            </FlexSpace>

        }
    },
    {
        title: 'Task Name',
        dataIndex: 'title',
        field: 'title',
        sorter: true
    },
    {
        title: 'Company',
        field: 'application',
        key: 'application',
        sorter: 'application',
        render: (row: ITodo) => {
            const {project} = row || {};
            const {application} = project || {};
            const {alias, name} = application || {}
            return <FlexSpace>
                {alias || name}
            </FlexSpace>

        }
    },
    {
        title: 'Assignee',
        dataIndex: 'user',
        field: 'user',
        sorter: true,
        render: (user: any) => {
            return <p>
                {user?.firstName} {user?.lastName}
            </p>
        }
    },
    {
        title: 'Due Date',
        sorter: true,
        field: 'dueDate',
        dataIndex: 'dueDate',
        render: (dueDate: string) => {
            return <FlexSpace>
                {dueDate ? toSafeDateString(dueDate) : ''}
            </FlexSpace>
        }
    },
    {
        title: 'Status',
        sorter: true,
        dataIndex: 'status',
        field: 'status',
        render: (status: number) => {
            return <FlexSpace>
                {getToDoStatusString(status)}
            </FlexSpace>
        }
    }
]
