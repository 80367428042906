import React, {useEffect, useState} from 'react'
import {Col, Form, Row, Select} from 'antd'
import {useRecoilValue} from 'recoil'
import {companiesAtom} from '../recoil/companies.state'
import {api} from '../utils/axios'
import {IUser} from '../types/user.types'
import {useForm} from 'antd/es/form/Form'
import {FlexSpace} from './FlexSpace'
import {Search} from "./Search";

interface ToDoFilterProps {
    onCompanyChange: (companyId: string[]) => void
    onStatusChange: (statusId: string[]) => void
    onMemberChange: (memberIds: string[]) => void
    setSearch: (search: string) => void
    search: string
}

export interface OptionType {
    value: any;
    label: string;
}

export const ToDoFilter = ({onCompanyChange, onMemberChange, onStatusChange, setSearch, search}: ToDoFilterProps) => {
    const [form] = useForm()
    const companies = useRecoilValue(companiesAtom)
    const [members, setMembers] = useState<OptionType[]>([]);
    const statuses: OptionType[] = [
        {label: 'New', value: 1},
        {label: 'In Progress', value: 2},
        {label: 'Completed', value: 3},
        {label: 'On hold', value: 4},
        {label: 'Canceled', value: 5},
    ]

    const companiesList: any = companies
        .map((v) => ({
            name: v.alias || v.name,
            value: v.id,

            label: (
                <FlexSpace>
                    <span>{v.alias || v.name}</span>
                </FlexSpace>
            )
        }))

    const membersList: any = members
        .map((v) => ({
            name: v.label,
            value: v.value,
            label: v.label,
        }))

    const statusesList: any = statuses
        .map((v) => ({
            name: v.label,
            value: v.value,

            label: (
                <FlexSpace>
                    <span>{v.label}</span>
                </FlexSpace>
            )
        }))


    useEffect(() => {
        fetchMembers()
    }, [])

    const fetchMembers = async () => {
        try {
            const {data} = await api.get(`/applications/members`);
            const newMembers = data.map((member: IUser) => ({
                ...member,
                value: member.id,
                label: member.firstName + ' ' + member.lastName
            }))
            setMembers(newMembers);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    return (
        <Form form={form} layout="vertical" >
            <Row gutter={16} >
                <Col span={12}>
                    <Form.Item label="Search by Task name" name="search">
                        <FlexSpace size='large'>
                            <Search value={search} onChange={setSearch}
                                    width={300}
                            />
                        </FlexSpace>
                    </Form.Item>
                </Col>
                <Col span={12} >
                    <Form.Item label="Filter by Status" name="status">
                        <Select
                            className="SelectPrimary"
                            maxTagCount={1}
                            placeholder="Select status"
                            style={selectStyle}
                            onChange={(values) => {
                                onStatusChange(values);
                            }}
                            filterOption={(input, option) =>
                                option?.name?.toLowerCase().includes(input.toLowerCase())
                            }
                            allowClear
                            showSearch
                            options={statusesList}
                            mode="multiple"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>

                    <Form.Item label="Filter by Company" name="application">
                        <Select
                            className="SelectPrimary"
                            maxTagCount={1}
                            placeholder="Select a company"
                            style={selectStyle}
                            onChange={(value) => {
                                onCompanyChange(value);
                            }}
                            filterOption={(input, option) =>
                                option?.name?.toLowerCase().includes(input.toLowerCase())
                            }
                            allowClear
                            showSearch
                            options={companiesList}
                            mode="multiple"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Filter by Assignee" name="user">
                        <Select
                            className="SelectPrimary"
                            maxTagCount={1}
                            placeholder="Select members"
                            style={selectStyle}
                            onChange={(values) => {
                                onMemberChange(values);
                            }}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option?.name?.toLowerCase().includes(input.toLowerCase())
                            }
                            options={membersList}
                            mode="multiple"
                        />
                    </Form.Item>
                </Col>

            </Row>
        </Form>
    )
}


const selectStyle: React.CSSProperties = {
    width: 300,
    padding: 0
}
