import { ICompany} from './company.types'
import { IUser } from './user.types'


export interface ToDoResponse {
  count: number
  data: ITodo[]
}

export interface ITodo {
  id: number
  title: string
  description: string
  status: TODO_STATUSES_ENUM
  assignee: IUser
  company: ICompany
  createdAt: Date
  updatedAt: Date
  dueDate: Date
  application: ICompany
  project: Project
}

export enum TODO_STATUSES_ENUM {
  New = 1,
  'In progress' = 2,
  Completed = 3,
  'On hold' = 4,
  Canceled = 5,
}

const TODO_STATUSES_MAP: { [key: number]: string } = {
  [TODO_STATUSES_ENUM.New]: 'New',
  [TODO_STATUSES_ENUM['In progress']]: 'In progress',
  [TODO_STATUSES_ENUM.Completed]: 'Completed',
  [TODO_STATUSES_ENUM['On hold']]: 'On hold',
  [TODO_STATUSES_ENUM.Canceled]: 'Canceled',
};

export function getToDoStatusString(status: number): string {
  return TODO_STATUSES_MAP[status].replace(/_/g, ' ') || 'UNKNOWN';
}


export interface Project {
  accounting_period: PROJECT_ACCOUNTING_PERIOD_ENUM,
  allToDos: number,
  completedToDos: number,
  application: ICompany
  dueDate: Date
  startDate: Date
  status: number
  type: number
  id: number
  name: string,
  user: IUser
  company: ICompany
  recurring: PROJECT_RECURRING_ENUM
  createdAt: Date
  updatedAt: Date
}

export enum PROJECT_RECURRING_ENUM {
  'Yes' = 1,
  'No' = 2,
}

export enum PROJECT_ACCOUNTING_PERIOD_ENUM {
  'Weekly' = 1,
  'Monthly' = 2,
  'Quarterly' = 3,
  'Yearly' = 4,
}

export enum PROJECT_STATUSES_ENUM {
  New = 1,
  'In progress' = 2,
  Completed = 3,
  'On hold' = 4,
  Canceled = 5,
}

export function getProjectAccountingPeriodString(status: number): string {
  return PROJECT_ACCOUNTING_PERIOD_ENUM[status].replace(/_/g, ' ') || 'UNKNOWN';
}

export function getProjectRecurringStatusString(status: number): string {
  return PROJECT_RECURRING_ENUM[status].replace(/_/g, ' ') || 'UNKNOWN';
}

export function getProjectStatusString(status: number): string {
  return PROJECT_STATUSES_ENUM[status].replace(/_/g, ' ') || 'UNKNOWN';
}

