import { Suspense, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Tabs } from 'antd'

import { Page } from 'components/Page'
import { FullLoader } from 'components/FullLoader'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { HeadTitle } from 'components/HeadTitle'

import { ComplianceRun } from './tabs/ComplianceRun'
import { ComplianceHistory } from './tabs/ComplianceHistory'
import { ComplianceResult } from './tabs/ComplianceResult'

import { currentCompany, currentCompliances } from 'recoil/companies.state'
import { ICompliance } from 'types/compliance.types'

export const Compliance: ReactFC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const company = useRecoilValue(currentCompany)
  const list = useRecoilValue(currentCompliances)

  const viewOnly = company?.role.id === 2000

  const [result, setResult] = useState<ICompliance>()
  const [items, setItems] = useState<any>(getItems(viewOnly, !!result))

  const baseUrl = `/company/${company?.id}/compliance`
  const match = useMatch(baseUrl + '/results/:resultId')
  const pathname = location.pathname.replace(baseUrl, '')
  const resultId = (match as any)?.params.resultId
  const activeKey = match ? '/results/' : pathname

  useEffect(() => {
    const r = list.find((v) => v.id === parseInt(resultId))
    if (resultId !== 'current') {
      setResult(r)
      setItems(getItems(viewOnly, !!r))
    } else setItems(getItems(viewOnly, true))
  }, [list, resultId])

  const handleRun = (r: ICompliance) => {
    setResult(r)
    setItems(getItems(viewOnly, !!r))
    navigate(baseUrl + '/results/current')
  }

  const handleChange = (key: string) => navigate(`${baseUrl}${key}`)

  if (!company) return <FullLoader />
  if (!result?.results && activeKey === '/results/' && resultId === 'current')
    return <Navigate replace to={`${baseUrl}/run`} />

  return (
    <Page title={'Financial Review'}>
      <HeadTitle title="Financial Review" />
      <Tabs
        items={items}
        onChange={(key) => handleChange(key)}
        activeKey={activeKey}
        style={{ marginBottom: -16 }}
        className="NoSelect"
        // tabBarExtraContent={<HelpBtn type="text" />}
      />
      <ErrorBoundary>
        <Suspense fallback={<FullLoader />}>
        <Routes>
          <Route path="/run" element={<ComplianceRun onChange={handleRun} />} />
          <Route path="/results" element={<ComplianceHistory list={list} />} />
          <Route path="/results/:id" element={<ComplianceResult result={result} list={list} onChange={setResult} />} />
          <Route path="*" element={<Navigate replace to={viewOnly ? `${baseUrl}/results` : `${baseUrl}/run`} />} />
        </Routes>
        </Suspense>
      </ErrorBoundary>
    </Page>
  )
}

function getItems(viewOnly: boolean, result: boolean) {
  const r =  result
    ? [
        { label: 'Run', key: '/run' },
        { label: 'History', key: '/results' },
        { label: 'Results', key: '/results/' }
      ]
    : [
        { label: 'Run', key: '/run' },
        { label: 'History', key: '/results' }
      ]

  if (viewOnly) r.shift()
  return r
}
